import React from "react";
import stepArrowIcon from "../../../assets/stepArrowIcon.PNG";

const BenchmarkHowToGetThereContainers = ({ pointIndex, pointDescription }) => {
    return (
        <div className="howtoGetThereRecommendationsCardContainer">
            <img src={stepArrowIcon} width="20px" height="20px" alt="Step Arrow Icon" className="howtoGetThereRecommendationsStepArrowIcon" />
            <div className="howToGetThereRecommendationPointIndex">Recommendations</div>
            <div className="howtoGetThereRecommendationsTextDescriptionPoint">{pointDescription}</div>
        </div>
    );
};

export default BenchmarkHowToGetThereContainers;