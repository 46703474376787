import React from "react";
import fileAttachedIcon from "../../../assets/fileAttachedIcon.png";
import fileViewIcon from "../../../assets/viewIcon.png";
import fileDownloadIcon from "../../../assets/downloadIcon.png";
import axios from 'axios';
import { notification } from "antd";
import { getFileAttachedDateTime } from "../../FormattedDate";

import "../../../css/RemediationSidebarAttachmentComponent.css";

const RemediationSidebarAttachmentComponent = ({ remediationReportData }) => {
    const attachmentsData = remediationReportData.attachments.length !== 0;
    const [api, contextHolder] = notification.useNotification();
    // https://pdfobject.com/pdf/sample.pdf

    const downloadAttachment = async (attachmentURL, attachmentFileName) => {
        try {
            const response = await axios.get(
                `${attachmentURL}`,
                {
                    responseType: "blob",
                }
            );

            // Create a Blob from the response data
            const pdfBlob = new Blob([response.data], { type: "application/pdf" });

            // Create a temporary URL for the Blob
            const url = window.URL.createObjectURL(pdfBlob);

            // console.log("url generated for download blob = ", url);

            // Create a temporary <a> element to trigger the download
            const tempLink = document.createElement("a");
            tempLink.href = url;
            tempLink.setAttribute(
                "download",
                `${attachmentFileName}`
            ); // Set the desired filename for the downloaded file
            tempLink.setAttribute("target", "_blank"); // Ensure the link opens in a new tab

            // Append the <a> element to the body and click it to trigger the download
            document.body.appendChild(tempLink);
            tempLink.click();

            // Clean up the temporary elements and URL
            document.body.removeChild(tempLink);
            window.URL.revokeObjectURL(url);


        } catch (error) {
            // console.error("Error downloading PDF:", error);
        }
    };

    return (
        <div className="remediationSidebarAttachmentSection">
            {contextHolder}
            {attachmentsData &&
                <>
                    <div className="remediationSidebarAttachmentTitle">Attachments</div>
                    <div className="remediationSidebarAttachmentContent">
                        {remediationReportData.attachments.map((attachment, index) => (
                            <div className="file-attachement-row" key={index}>
                                <div class="attached-file-info">
                                    <img src={fileAttachedIcon} alt="File Attached Icon" class="attached-file-icon" width={41} height={41} />
                                    <div class="attached-file-text-details">
                                        <div class="attached-file-name">{attachment.name == null ? "No File Name" : attachment.name}</div>
                                        <div class="attached-file-date">{getFileAttachedDateTime(attachment.createdAt)}</div>
                                    </div>
                                </div>

                                <div class="attached-file-actions">
                                    <button class="attached-file-view-button">
                                        <img src={fileViewIcon} alt="View" width={27} height={27} />
                                        <span className="file-action-span-text">View</span>
                                    </button>
                                    {/* <button class="attached-file-download-button">
                                        <img src={fileDownloadIcon} alt="Download" width={20} height={20} />
                                        <span className="file-action-span-text">Download</span>
                                    </button> */}
                                    <a href={attachment.file} target="_blank" rel="noopener noreferrer" className="attached-file-download-button">
                                        <img src={fileDownloadIcon} alt="Download" width={20} height={20} />
                                        <span className="file-action-span-text">Download</span>
                                    </a>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            }
        </div>
    )
};

export default RemediationSidebarAttachmentComponent;