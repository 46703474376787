import React, { useEffect } from "react";
import MainNavbarForDashboard from "../components/navbarComponents/NavbarForMainDashbaord";
import MeetingsPageContainer from "../components/meetings/MeetingsPageContainer";
import "../css/MeetingsPage.css";

const isNavbarForDashboard = true;
const MeetingsPage = () => {
    const user_details = JSON.parse(sessionStorage.getItem("user_details"));
    // console.log("in main dashboard page where the logged_in_user_id = ", user_details);
    
    return (
        <div className="completePageContainer">
            <MainNavbarForDashboard
                isNavbarForDashboard={isNavbarForDashboard}
                user_details={user_details}
                current_tab="meetings"
            />
            <div className="mainContentBelowTheNavbar meetingsScreenContainer">
                <MeetingsPageContainer user_details={user_details} />
            </div>
        </div>
    )
};

export default MeetingsPage;