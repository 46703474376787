import React from "react";
import MainNavbarForDashboard from "../components/navbarComponents/NavbarForMainDashbaord";
import AssessmentFormMainScreen from "../components/AssessmentFormMainScreen";
import { useLocation } from "react-router-dom";
import "../css/dashboard.css";

const isNavbarForDashboard = true;

const AssessmentForm = () => {
  // const location = useLocation();
  // const state_from_login = location.state || {};
  // const logged_in_user_id = state_from_login.loggedInUserID != undefined ? state_from_login.loggedInUserID : sessionStorage.getItem("loggedInUserID");
  // console.log("in assessment form logged_in_user_id = " , logged_in_user_id);
  // const user_access_token = state_from_login.user_access_token != undefined ? state_from_login.user_access_token : "";
  const user_details = JSON.parse(sessionStorage.getItem("user_details"));
  // console.log("user details in the assessment page - ", user_details);

  return (
    <div className="completePageContainer">
      <MainNavbarForDashboard
        isNavbarForDashboard={isNavbarForDashboard}
        user_details={user_details}
        current_tab="start"
      />
      <div className="mainContentBelowTheNavbar">
        <AssessmentFormMainScreen user_details={user_details}/>
      </div>
    </div>
  );
};

export default AssessmentForm;
