import React from "react";
import { Tag } from "antd";
import { formatToLocalDate, formatTimelineEndDate, formatTimeStringToDateForTimeline } from "../../FormattedDate";
import "../../../css/RemediationSidebarHeaderComponent.css";

const RemediationSidebarHeaderComponent = ({ remediationReportData }) => {

    const calculateAllTasksBudget = (data) => {
        let totalBudget = 0;
        // Loop through each milestone in the data

        // data coming into the function
        // console.log("data coming into the calculate total tasks budget function is == ", data);

        data.forEach(milestone => {
            // Loop through each task in the milestone
            milestone.tasks.forEach(task => {
                // Extract the numeric portion of the budget using regular expressions
                const budget = parseFloat(task.budget.replace(/[^0-9.]/g, ''));
                // Sum up the budgets
                totalBudget += budget;
            });
        });
        return totalBudget;
    };

    return (
        <div className="remediationSidebarHeaderSection">
            <div className="remediationSidebarHeaderTitle">{remediationReportData.client}</div>
            <div className="remediationSidebarHeaderSubTitle">Gap fixes for  {remediationReportData.name}</div>
            <div className="remediationSidebarHeader-tags-container">
                <Tag className='remediationSidebarHeader-tag firstTag'>High priority</Tag>
                <Tag className='remediationSidebarHeader-tag secondTag'>{formatTimeStringToDateForTimeline(remediationReportData.createdAt)} - {formatTimelineEndDate(remediationReportData.end_date)}</Tag>
                <Tag className='remediationSidebarHeader-tag thirdTag'>${calculateAllTasksBudget(remediationReportData.milestones)}</Tag>
                <Tag className='remediationSidebarHeader-tag fourthTag'>{remediationReportData.completion_level}% complete</Tag>
            </div>
        </div>
    )
};

export default RemediationSidebarHeaderComponent;