import React from "react";
import { getInitials } from "./GetInitialsFunction";

const IncomingChatMessageComponent = ({incomingChatMessage}) => {
    return (
        <div className="message incoming">
            <div className="avatar avatarForOutIncomingMessage">{getInitials(incomingChatMessage.receiver.name)}</div>
            <div className="message-content">
                <div className="message-name">{incomingChatMessage.receiver.name}</div>
                <div className="message-text">
                    {incomingChatMessage.message}
                </div>
            </div>
        </div>
    );
};

export default IncomingChatMessageComponent;