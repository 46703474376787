import React from "react";
import backButtonImage from "../../assets/backButtonImage.png";

import "../../css/MeetingsContainerHeader.css";

const MeetingsContainerHeader = ({ openNewMeetingModalCallback }) => {

    const openNewMeetingModal = () => {
        openNewMeetingModalCallback("sending data in to the modal being opened");
    }
    return (
        <div className="meetingsContainerHeader">
            <div className="backAndSearchContainer">
                <div className="backButtonForMeetingsContainer">
                    <img src={backButtonImage} alt="Back" className="meetingsBackButtonImage" />
                    <div className="meetingsBackButtonText">Back</div>
                </div>
                <div className="searchContainerForMeetingsContainer">
                    <input type="text" placeholder="Search by company name" class="meetings-container-search-input" />
                </div>
            </div>
            <div className="newMeetingsButton" onClick={openNewMeetingModal}>New Meeting</div>
        </div>
    );
};

export default MeetingsContainerHeader;