import React from "react";
import { getInitials } from "./GetInitialsFunction";

const OutgoingChatMessageComponent = ({outgoingChatMessage}) => {
    return (
        <div className="message outgoing">
            <div className="message-content">
                <div className="message-name">{outgoingChatMessage.sender.name}</div>
                <div className="message-text">
                    {outgoingChatMessage.message}
                </div>
            </div>
            <div className="avatar avatarForOutGoingMessage">{getInitials(outgoingChatMessage.sender.name)}</div>
        </div>
    );
};

export default OutgoingChatMessageComponent;