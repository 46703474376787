import React, { useState } from "react";

const AssessmentReportSectionNavigationComponentForBenchmark = ({ parentStateForActiveTab, analysisTabRefs, benchmarksTabData = {} }) => {
    const [activeSection, setActiveSection] = useState("Market Opportunity");

    const handleClickAssessmentReportAnalysisNavigationItem = (sectionName, ref) => {
        setActiveSection(sectionName);  // Set the active section
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className="listOfAssessmentReportSections">
            {(benchmarksTabData && benchmarksTabData.report && benchmarksTabData.report["Market Opportunity"]) && (
                <div
                    className={(activeSection === "Market Opportunity") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Market Opportunity", analysisTabRefs.marketOpportunity)}
                >
                    Market Opportunity
                </div>
            )}

            {(benchmarksTabData?.report?.["Competitive Landscape"]) && (
                <div
                    className={(activeSection === "Competitive Landscape") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Competitive Landscape", analysisTabRefs.competitiveLandscape)}
                >
                    Competitive Landscape
                </div>
            )}

            {(benchmarksTabData?.report?.["Product & Development Stage"]) && (
                <div
                    className={(activeSection === "Product & Development Stage") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Product & Development Stage", analysisTabRefs.productAndDevelopmentStage)}
                >
                    Product & Development Stage
                </div>
            )}

            {(benchmarksTabData?.report?.["Financial Metrics"]) && (
                <div
                    className={(activeSection === "Financial Metrics") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Financial Metrics", analysisTabRefs.financialMetrics)}
                >
                    Financial Metrics
                </div>
            )}

            {(benchmarksTabData?.report?.["Traction & Market Penetration"]) && (
                <div
                    className={(activeSection === "Traction & Market Penetration") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Traction & Market Penetration", analysisTabRefs.tractionAndMarketPenetration)}
                >
                    Traction & Market Penetration
                </div>
            )}

            {(benchmarksTabData?.report?.["Risk Analysis"]) && (
                <div
                    className={(activeSection === "Risk Analysis") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Risk Analysis", analysisTabRefs.riskAnalysis)}
                >
                    Risk Analysis
                </div>
            )}

            {(benchmarksTabData?.report?.["Team & Experience"]) && (
                <div
                    className={(activeSection === "Team & Experience") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Team & Experience", analysisTabRefs.teamAndExperience)}
                >
                    Team & Experience
                </div>
            )}

            {(benchmarksTabData?.report?.["Governance & Support"]) && (
                <div
                    className={(activeSection === "Governance & Support") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Governance & Support", analysisTabRefs.governanceAndSupport)}
                >
                    Governance & Support
                </div>
            )}

            {(benchmarksTabData?.report?.["Revenue Model & Growth"]) && (
                <div
                    className={(activeSection === "Revenue Model & Growth") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Revenue Model & Growth", analysisTabRefs.revenueModelAndGrowth)}
                >
                    Revenue Model & Growth
                </div>
            )}

            {(benchmarksTabData?.report?.["Exit Strategy"]) && (
                <div
                    className={(activeSection === "Exit Strategy") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Exit Strategy", analysisTabRefs.exitStratergy)}
                >
                    Exit Strategy
                </div>
            )}

            {(benchmarksTabData?.report?.["Funding and Capital Requirements"]) && (
                <div
                    className={(activeSection === "Funding and Capital Requirements") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Funding and Capital Requirements", analysisTabRefs.fundingAndCapitalRequirements)}
                >
                    Funding and Capital Requirements
                </div>
            )}

            {(benchmarksTabData && benchmarksTabData.report) && (
                <div
                    className={(activeSection === "Summary of Rankings") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Summary of Rankings", analysisTabRefs.summaryOfRanking)}
                >
                    Summary of Rankings
                </div>
            )}

        </div>
    );
};

export default AssessmentReportSectionNavigationComponentForBenchmark;