import React from 'react';
import AssessmentFormHeaderTitle from "../components/AssessmentFormHeaderTitle";
import AssessmentFormProgressBar from "../components/AssessmentFormProgressBar";
import AssessmentFormHeaderSectionInfo from "../components/AssessmentFormHeaderSectionInfo";
import AssessmentFormHeaderSaveAndExitButton from "../components/AssessmentFormHeaderSaveAndExitButton";
import '../css/assessmentFormHeaderContainer.css'; // Import the custom CSS file

const AssessmentFormHeader = ({ progress, sectionInformationToBeDisplayed, totalNumberOfSections, onPreviousButtonClick = () => { }, onSaveAndExitButtonClick = () => { } }) => {
  return (
    <div className="assessmentFormHeaderContainer">
      {/* <AssessmentFormHeaderSaveAndExitButton
        buttonTitle={"Previous"}
        buttonPosition={"left"}
        onPreviousButtonClick={onPreviousButtonClick}
      /> */}
      <AssessmentFormHeaderTitle
        assessmentFormHeaderTitle="Welcome to Anvil List, your trusted partner on the path to investment readiness."
      />
      <AssessmentFormHeaderSaveAndExitButton buttonTitle={"Save and Exit"} onSaveAndExitButtonClick={onSaveAndExitButtonClick} />
      <AssessmentFormProgressBar progress={progress} />
      <AssessmentFormHeaderSectionInfo
        sectionNumber={sectionInformationToBeDisplayed.section}
        sectionName={sectionInformationToBeDisplayed.sectionName}
        totalNumberOfSections={totalNumberOfSections} />
    </div>
  );
};

export default AssessmentFormHeader;
