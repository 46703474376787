import React from "react";
import searchIcon from "../../assets/search.png";
import UserChatSearchComponent from "./UserChatSearchComponent";
import UserRecentChatListComponenet from "./UserRecentChatListComponenet";

const SidebarComponent = ({ onActiveChatChange, user_details }) => {

    return (
        <div className="sidebar">
            <UserChatSearchComponent />
            <UserRecentChatListComponenet onChatClick={onActiveChatChange} user_details={user_details}/>
        </div>
    );
};

export default SidebarComponent;