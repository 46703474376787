import React, { useState } from "react";
import { Flex, Spin } from "antd";
import NoDataViewComponent from "../mainDashboardPageComponents/NoDataView";
import AssessmentAnalysisContent from "./Analysis/AssessmentAnalysisContent";
import AssessmentInvestmentReadinessContent from "./investmentReadiness/AssessmentInvestmentReadinessContent";
import AssessmentExecutableSolutionsContent from "./executableSolutions/AssessmentExecutableSolutionsContent";
import AssessmentBenchmarksContent from "./benchmarks/AssessmentBenchmarksContent";


import "../../css/AssessmentReportDetailContainer.css";

const AssessmentReportDetailContainer = ({
    parentStateForActiveTab,
    sendDataToParent,
    analysisTabRefs,
    analysisTabData,
    executableSolutionsTabData,
    benchmarksTabData,
    investmentReadinessTabData,
    completeReportData
}) => {
    const [activeTab, setActiveTab] = useState('Analysis');
    const [loading, setLoading] = useState(false);

    const renderContentForAnalysis = () => {
        if (false) {
            return <NoDataViewComponent title="No Data available at this time" />
        }
        else {
            return (
                <AssessmentAnalysisContent parentStateForActiveTab={parentStateForActiveTab} analysisTabRefs={analysisTabRefs} analysisTabData={analysisTabData} />
            )
        }
    };

    const renderContentForInvestmentReadiness = () => {
        if (false) {
            return <NoDataViewComponent title="No Data available at this time" />
        }
        else {
            return (
                <AssessmentInvestmentReadinessContent
                    parentStateForActiveTab={parentStateForActiveTab}
                    analysisTabRefs={analysisTabRefs}
                    analysisTabData={analysisTabData}
                    executableSolutionsTabData={executableSolutionsTabData}
                    benchmarksTabData={benchmarksTabData}
                    investmentReadinessTabData={investmentReadinessTabData}
                    completeReportData={completeReportData}
                />
            )
        }
    };

    const renderContentFortExecutableSolutions = () => {
        if (false) {
            return <NoDataViewComponent title="No Data available at this time" />
        }
        else {
            return (
                <AssessmentExecutableSolutionsContent
                    parentStateForActiveTab={parentStateForActiveTab}
                    analysisTabRefs={analysisTabRefs}
                    executableSolutionsTabData={executableSolutionsTabData}
                />
            )
        }
    };

    const renderContentFortBenchmarks = () => {
        if (false) {
            return <NoDataViewComponent title="No Data available at this time" />
        }
        else {
            return (
                <AssessmentBenchmarksContent parentStateForActiveTab={parentStateForActiveTab} analysisTabRefs={analysisTabRefs} benchmarksTabData={benchmarksTabData} />
            )
        }
    };


    const renderContent = () => {
        if (loading) {
            // return <div>Loading...</div>;
            return (
                <Flex align="center" gap="middle">
                    <Spin size="large" className='spinner-container' />
                </Flex>
            );
        }
        switch (activeTab) {
            case 'Analysis':
                return (
                    <div style={{ width: '100%' }}>
                        {renderContentForAnalysis()}
                    </div>
                );
            case 'Executable solutions':
                // return <div className="content">This is the Recommendations content. </div>;
                return (
                    <div style={{ width: '100%' }}>
                        {renderContentFortExecutableSolutions()}
                    </div>
                )
            case 'Benchmarks':
                // return <div className="content">This is the Timeline content.</div>;
                return (
                    <div style={{ width: '100%' }}>
                        {renderContentFortBenchmarks()}
                    </div>
                )
            case 'Investment Readiness':
                // return <div className="content">This is the Timeline content.</div>;
                return (
                    <div style={{ width: '100%' }}>
                        {renderContentForInvestmentReadiness()}
                    </div>
                )
            default:
                return null;
        }
    };

    const handleActiveTabChange = (activeTabName) => {
        setActiveTab(activeTabName);
        sendDataToParent(activeTabName); // Call the parent's function and pass the data
    };

    return (
        <div>
            <div className="remediation-tabs-container assessment-report-detail-tabs-container">
                <div className="remediation-tabs">
                    <div
                        className={`remediation-tab assessment-report-detail-tab ${activeTab === 'Analysis' ? 'active' : ''}`}
                        onClick={() => handleActiveTabChange("Analysis")}
                    >
                        Analysis
                    </div>
                    <div
                        className={`remediation-tab assessment-report-detail-tab ${activeTab === 'Executable solutions' ? 'active' : ''}`}
                        onClick={() => handleActiveTabChange("Executable solutions")}
                    >
                        Executable solutions
                    </div>
                    <div
                        className={`remediation-tab assessment-report-detail-tab ${activeTab === 'Benchmarks' ? 'active' : ''}`}
                        onClick={() => handleActiveTabChange("Benchmarks")}
                    >
                        Benchmarks
                    </div>
                    <div
                        className={`remediation-tab assessment-report-detail-tab ${activeTab === 'Investment Readiness' ? 'active' : ''}`}
                        onClick={() => handleActiveTabChange("Investment Readiness")}
                    >
                        Investment Readiness
                    </div>
                </div>
            </div>
            {renderContent()}
        </div>
    );
};

export default AssessmentReportDetailContainer;