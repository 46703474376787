import React from "react";
import stepArrowIcon from "../../../assets/stepArrowIcon.PNG";

const ExecutableSolutionsCardContainer = ({ pointIndex, pointDescription }) => {
    return (
        <div className="executableSolutionsCardContainer">
            <img src={stepArrowIcon} width="20px" height="20px" alt="Step Arrow Icon" className="actionableStepArrowIcon" />
            {pointIndex === "11 Conclusion" ? (
                <div className="actionableStepTextDescriptionPoint">{pointDescription}</div>
            ) : (
                <div className="actionableStepTextDescriptionPoint">{parseInt(pointIndex) + 1}. {pointDescription}</div>
            )}

        </div>
    );
};

export default ExecutableSolutionsCardContainer;