import React from "react";
import problemIcon from "../../../assets/problemIcon.PNG";
import solutionIcon from "../../../assets/solutionIcon.PNG";
import actionableStepsIcon from "../../../assets/actionableStepsIcon.PNG";
import ExecutableSolutionsCardContainer from "./ExecutableSolutionsCardContainer";

const MarketOpportunityForExecutableSolutions = ({ sectionTitle, actionableSteps, problemSolution, sectionData = {} }) => {
    const problem_value = (sectionData && sectionData["Problem"]) ? sectionData["Problem"] : "";
    const solution_value = (sectionData && sectionData["Solution"]) ? sectionData["Solution"] : "";
    const actionable_steps = (sectionData && sectionData["Actionable_steps"]) ? sectionData["Actionable_steps"] : [];
    return (
        <div className="executableSectionContainer">
            <div className="executableSectionHeader">{sectionTitle}</div>
            {sectionTitle === "11 Conclusion" ? (
                <div className="actionableStepsContentContainer">
                    {actionableSteps.map((point, index) => (
                        <ExecutableSolutionsCardContainer key={index} pointIndex={sectionTitle} pointDescription={point} />
                    ))}
                </div>
            ) : (
                <>
                    <div className="problemSolutionContainer">
                        <div className="problemContainer">
                            <img src={problemIcon} width="30px" height="25px" alt="Problem Icon" />
                            <div className="problemTitle">Problem</div>
                            <div className="problemDescription">{problem_value}</div>
                        </div>
                        <div className="solutionContainer">
                            <img src={solutionIcon} width="30px" height="25px" alt="Solution Icon" />
                            <div className="solutionTitle">Solution</div>
                            <div className="solutionDescription">{solution_value}</div>
                        </div>
                    </div>
                    <div className="actionableStepsTitleContainer">
                        <img src={actionableStepsIcon} width="30px" height="32px" alt="Actionable Steps Icon" />
                        <div className="actionableStepsTitleText">Actionable Steps</div>
                    </div>
                    <div className="actionableStepsContentContainer">
                        {actionable_steps.map((point, index) => (
                            <ExecutableSolutionsCardContainer key={index} pointIndex={index} pointDescription={point} />
                        ))}
                    </div>
                </>
            )}
        </div>
    );

}

export default MarketOpportunityForExecutableSolutions;