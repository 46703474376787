import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Spin, Select  } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const { Option } = Select;

const SignupFormContainerComponent = ({ form, formItems, onFinish, buttonText, comingFrom = "", fieldErrors = {}, isLoading, dropdownOptionsFromIndustryApi = [] }) => {
    const [password, setPassword] = useState("");
    const [passwordCriteria, setPasswordCriteria] = useState({
        hasLowercase: false,
        hasUppercase: false,
        hasNumber: false,
        hasMinLength: false,
    });

    const list_password_field_names = [
        "Create your password", // sign up 
        "Confirm password", // sign up 
        "Password", // login 
        "Enter new password", // reset password
        "Confirm new password" // reset password
    ];

    const create_new_password_field_labels = [
        "Create your password", // sign up 
        "Enter new password", // reset password
    ];

    const confirm_password_field_labels = [
        "Confirm password", // sign up 
        "Confirm new password" // reset password
    ]

    const validatePassword = (password) => {
        setPasswordCriteria({
            hasLowercase: /[a-z]/.test(password),
            hasUppercase: /[A-Z]/.test(password),
            hasNumber: /\d/.test(password),
            hasMinLength: password.length >= 8,
            hasSpecialCharacter: /[!@#$%^&*(),.?":{}|<>]/.test(password),
        });
    };

    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        // console.log("printing out the password value = ", newPassword);
        setPassword(newPassword);
        validatePassword(newPassword);
        // console.log('Form instance:', form);
        form.setFieldsValue({ [e.target.name]: newPassword });
    };

    const dropDown_field_names = [
        "What industry is your business in?",
    ]
    
    return (
        <div className='signup-forms'>
            <Form form={form} onFinish={onFinish} size='large'>
                {formItems.map((item, index) => (
                    <Form.Item
                        layout='vertical'
                        key={index}
                        label={item.label}
                        name={item.label}
                        rules={item.rules} // Apply validation rules here
                        style={{ marginBottom: ((create_new_password_field_labels.includes(item.label) && password.length > 0) ? "165px" : "16px"), minHeight: "110px" }} // Added minHeight to prevent shifting
                        help={fieldErrors[item.label]}
                        validateStatus={fieldErrors[item.label] ? 'error' : ''}
                    >
                        {(list_password_field_names.includes(item.label)) ? (
                            <>

                                {create_new_password_field_labels.includes(item.label) ? (
                                    <Input.Password
                                        size="small"
                                        name={item.label}
                                        value={password}
                                        onChange={handlePasswordChange}
                                        iconRender={(visible) =>
                                            visible ? (
                                                <EyeTwoTone />
                                            ) : (
                                                <EyeInvisibleOutlined style={{ padding: "10px", borderRadius: "15px" }} />
                                            )
                                        }
                                    />
                                ) : (
                                    <Input.Password
                                        size="small"
                                        iconRender={(visible) =>
                                            visible ? (
                                                <EyeTwoTone />
                                            ) : (
                                                <EyeInvisibleOutlined style={{ padding: "10px", borderRadius: "15px" }} />
                                            )
                                        }
                                    />
                                )}

                                {(create_new_password_field_labels.includes(item.label) && password.length > 0) ? (
                                    <div style={{ marginTop: '10px', minHeight: '80px', paddingBottom: '10px' }}>
                                        <p style={{ color: passwordCriteria.hasMinLength ? 'green' : 'red' }}>
                                            {passwordCriteria.hasMinLength ? `✔` : `✖`} Minimum 8 characters
                                        </p>
                                        <p style={{ color: passwordCriteria.hasLowercase ? 'green' : 'red' }}>
                                            {passwordCriteria.hasLowercase ? `✔` : `✖`} At least one lowercase letter
                                        </p>
                                        <p style={{ color: passwordCriteria.hasUppercase ? 'green' : 'red' }}>
                                            {passwordCriteria.hasUppercase ? `✔` : `✖`} At least one uppercase letter
                                        </p>
                                        <p style={{ color: passwordCriteria.hasNumber ? 'green' : 'red' }}>
                                            {passwordCriteria.hasNumber ? `✔` : `✖`} At least one number
                                        </p>
                                        <p style={{ color: passwordCriteria.hasSpecialCharacter ? 'green' : 'red' }}>
                                            {passwordCriteria.hasSpecialCharacter ? `✔` : `✖`} At least one special character
                                        </p>

                                    </div>
                                ) : (
                                    <>
                                    </>
                                )}
                            </>
                        ) : dropDown_field_names.includes(item.label) ? (
                            <Select
                                placeholder="Select an industry"
                                onChange={value => console.log(`selected ${value}`)}
                            >
                                {dropdownOptionsFromIndustryApi.map((option) => (
                                    <Option key={option} value={option}>
                                        {option}
                                    </Option>
                                ))}
                            </Select>
                        ) : (
                            <Input size="small" />
                        )}
                    </Form.Item>
                ))}
                {(comingFrom === "loginComponent") && (
                    // <Link to="/login">Forgot Password?</Link>
                    // <div className='forgotPassword' onClick={navigateToForgotPassword}>Forgot Password ?</div>

                    // forgotPassword
                    <Form.Item style={{ marginBottom: "0px" }}>
                        <div className='forgotPassword'>
                            <Link to="/forgotPassword" className='forgotPaswordTextStyling'>Forgot Password?</Link>
                        </div>
                    </Form.Item>
                )}

                <Form.Item>
                    <Spin spinning={isLoading}>
                        <Button type="primary" htmlType="submit" disabled={isLoading}>
                            {buttonText}
                        </Button>
                    </Spin>
                </Form.Item>
            </Form>
        </div>
    );
}

export default SignupFormContainerComponent;