import React from "react";
import MainNavbarForDashboard from "../navbarComponents/NavbarForMainDashbaord";
import BlogsContainer from "./BlogsContainer";
import BlogsFooter from "./BlogsFooter";
import BlogScreenHeader from "./BlogScreenHeader";

import "../../css/FixedScrollingMainScreenContent.css";

const isNavbarForDashboard = true;
const BlogScreen = () => {

    const user_details = JSON.parse(sessionStorage.getItem("user_details"));
    // console.log("in main dashboard page where the logged_in_user_id = ", user_details);

    return (
        <div className="completePageContainer">
            <MainNavbarForDashboard
                isNavbarForDashboard={isNavbarForDashboard}
                user_details={user_details}
                current_tab="blogs"
            />
            <div className="mainContentBelowTheNavbar blogScreenContainer">
                <BlogScreenHeader />
                <BlogsContainer />
                <BlogsFooter />
            </div>
        </div>
    )

};

export default BlogScreen;
