import React, { useState, useEffect } from "react";
import "../../css/ShortAnswerTextWithDropdown.css";

const ShortAnswerTextComponent = ({ initialState = "", onValueChange = {}, questionText = "" }) => {
    // Use local state to manage the text input value
    const [inputValue, setInputValue] = useState(initialState);
    const [selectedOption, setSelectedOption] = useState("months");

    // Effect to handle the logic for updating based on the input value
    useEffect(() => {
        const lowerCaseString = inputValue.toLowerCase();
        if (lowerCaseString.includes('months')) {
            setSelectedOption('months');
        } else if (lowerCaseString.includes('years')) {
            setSelectedOption('years');
        }

        const regex = /\b(months|years)\b/gi;
        const cleanedString = lowerCaseString.replace(regex, '').trim();
        setInputValue(cleanedString);
    }, [inputValue]); // Depend on inputValue to control when this effect runs

    // Handle input change and notify parent component
    const handleChange = (e) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        if (questionText && questionText === "How long has the company been operational?") {
            const joined_new_value = newValue + " " + selectedOption;
            onValueChange(joined_new_value); // Call the onValueChange prop function
        } else {
            onValueChange(newValue); // Call the onValueChange prop function
        }

    };

    const inputStyles = {
        width: "65%",
        maxWidth: "65%",
        padding: "10px",
        fontSize: "16px",
        border: "none",
        borderBottom: "1px solid #ccc",
        boxSizing: "border-box"
    };

    const inputStylesWithDropdown = {
        width: "95%",
        maxWidth: "95%",
        padding: "10px",
        fontSize: "16px",
        border: "none",
        borderBottom: "1px solid #ccc",
        boxSizing: "border-box"
    };

    const handleSelectChange = (event) => {
        const selectedValueFromTheDropdown = event.target.value;
        // console.log("selectedValueFromTheDropdown :- ", selectedValueFromTheDropdown);
        setSelectedOption(selectedValueFromTheDropdown);
        // onValueChange(selectedValueFromTheDropdown);
        if (questionText && questionText === "How long has the company been operational?") {
            const joined_new_value = inputValue + " " + selectedValueFromTheDropdown;
            onValueChange(joined_new_value); // Call the onValueChange prop function
        }
    };

    const dropdownOptions = [
        {
            "value": "months",
            "label": "months"
        },
        {
            "value": "years",
            "label": "years"
        }
    ];

    return (
        <>
            {(questionText && questionText === "How long has the company been operational?") ? (
                <div className="shortAnswerWithDropdown">
                    <div className="short-answer-text-container">
                        <input
                            type="text"
                            value={inputValue}
                            onChange={handleChange}
                            placeholder="Enter your answer"
                            style={inputStylesWithDropdown}
                        />
                    </div>
                    <div className="dropdown-container-with-short-text">
                        <select className="ant-select" value={selectedOption} onChange={handleSelectChange}>
                            {dropdownOptions.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
            ) : (
                <input
                    type="text"
                    value={inputValue}
                    onChange={handleChange}
                    placeholder="Enter your answer"
                    style={inputStyles}
                />
            )}
        </>
    );

};

export default ShortAnswerTextComponent;