import React from "react";
import SectionContent from "../sectionComponents/SectionContent";
import SectionTitle from "../sectionComponents/SectionTitle";

const ExitStratergy = ({ exitStratergySectionData }) => {
    const sample_section_estimates = "The World Agricultural Supply and Demand Estimates (WASDE) is prepared and released by the World Agricultural Outlook Board (WAOB). The report is released monthly, and provides annual forecasts for supply and use of U.S. and world wheat, rice, coarse grains, oilseeds, and cotton. ";
    const section_sample_content = "Lebanon’s health ministry says 492 killed, including 35 children and 58 women. More than 1,600 wounded. Israel says 1,300 Hezbollah assets struck. The report also covers U.S. supply and use of sugar, meat, poultry eggs and milk, as well as Mexico’s supply and use of sugar. The WAOB chairs the Interagency Commodity Estimates Committees (ICECs), which include analysts from key USDA agencies who compile and interpret information from USDA and other domestic and foreign sources to produce the report. For more information about the WASDE process and data, visit the WASDE FAQs page.";

    let blue_box_data;
    let blue_box_data_list;
    let blue_box_title;
    let blue_box_description;

    let green_box_data;
    let green_box_data_list;
    let green_box_title;
    let green_box_description;

    let analysisSectionTextDescription;

    if (Array.isArray(exitStratergySectionData)) {
        blue_box_data = exitStratergySectionData[0] ? exitStratergySectionData[0] : "Metric not Available";
        blue_box_data_list = blue_box_data !== "" ? blue_box_data.split(":") : null;
        blue_box_title = blue_box_data_list !== null ? blue_box_data_list[0] : "Exit Plan";
        blue_box_description = blue_box_data_list !== null ? blue_box_data_list[1] : "Metric Value not Available";

        green_box_data = exitStratergySectionData[1] ? exitStratergySectionData[1] : "Metric not Available";
        green_box_data_list = green_box_data !== "" ? green_box_data.split(":") : null;
        green_box_title = green_box_data_list !== null ? green_box_data_list[0] : "Investor Expectations";
        green_box_description = green_box_data_list !== null ? green_box_data_list[1] : "Metric Value not Available";

        analysisSectionTextDescription = exitStratergySectionData[2] ? exitStratergySectionData[2] : "Analysis is not available at this moment for this metric.";
    }
    else if (typeof exitStratergySectionData === "object" && exitStratergySectionData !== null && !Array.isArray(exitStratergySectionData)) {
        blue_box_title = "Exit Plan";
        blue_box_description = exitStratergySectionData["Exit Plan"];
        green_box_title = "Investor Expectations";
        green_box_description = exitStratergySectionData["Investor Expectations"];
        analysisSectionTextDescription = exitStratergySectionData["Analysis"];
    }
    else {
        blue_box_title = "Exit Plan";
        blue_box_description = "Metric Value not Available";
        green_box_title = "Investor Expectations";
        green_box_description = "Metric Value not Available";
        analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    }

    return (
        <div className="sectionContainer">
            <SectionTitle sectionTitle="Exit Stratergy" />
            <SectionContent
                sectionEstimates={sample_section_estimates}
                sectionContent={section_sample_content}
                blue_box_title={blue_box_title}
                blue_box_description={blue_box_description}
                green_box_title={green_box_title}
                green_box_description={green_box_description}
                analysisSectionTextDescription={analysisSectionTextDescription}
                comingFrom="Exit Stratergy"
            />
        </div>
    );
};

export default ExitStratergy;