import React from "react";
import MainNavbarForDashboard from "../components/navbarComponents/NavbarForMainDashbaord";
import RecommendedProfessaionalProfileSectionOne from "../components/recommendedProfilePageComponents/RecommendedProfessaionalProfileSectionOne";
import RecommendedProfessaionalProfileSectionTwo from "../components/recommendedProfilePageComponents/RecommendedProfessaionalProfileSectionTwo";
import RecommendedProfessaionalProfileSectionThree from "../components/recommendedProfilePageComponents/RecommendedProfessaionalProfileSectionThree";
import backButton from "../assets/backButtonImage.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import "../css/mainDashboardPage.css";
const isNavbarForDashboard = true;

const RecommendedProfessinalProfilePage = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { professionalPersonalInformation } = location.state || {};
    // console.log("in the recommended professional profile detail page where the professionalPersonalInformation = ", professionalPersonalInformation);

    const user_details = JSON.parse(sessionStorage.getItem("user_details"));
    // console.log("in the recommended professional profile detail page where the logged_in_user_id = ", user_details);

    const handleBackButtonClick = () => {
        // navigate("/mainDashboard");
        navigate(-1);  // This moves the navigation history back by one
    }

    return (
        <div className="completePageContainer">
            <MainNavbarForDashboard
                isNavbarForDashboard={isNavbarForDashboard}
                user_details={user_details}
                current_tab="no tab present for this"
            />
            <div className="mainContentBelowTheNavbar">
                {/* <div>this is the page to display the details of the recommended professional. </div> */}
                <div
                    className="back-button" 
                    style={{ marginLeft: "4.5%", marginBottom: "1%", marginTop: "2%", display: "flex", alignItems: "center", cursor: "pointer" }}
                    onClick={handleBackButtonClick}
                >
                    <img src={backButton} alt="Back Icon" width="20px" height="20px" style={{ marginTop: "3px", marginRight: "3px" }} />
                    <span style={{ fontWeight: "700", fontSize: "20px", }}>Back</span>
                </div>
                <RecommendedProfessaionalProfileSectionOne professionalPersonalInformation={professionalPersonalInformation}/>
                <RecommendedProfessaionalProfileSectionTwo professionalPersonalInformation={professionalPersonalInformation}/>
                <RecommendedProfessaionalProfileSectionThree professionalPersonalInformation={professionalPersonalInformation} user_details={user_details}/>
            </div>
        </div>

    );
};

export default RecommendedProfessinalProfilePage; 