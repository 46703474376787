import React from "react";

const WhiteBoxContainer = ({ white_box_title = "", white_box_description = "" }) => {
    return (
        <div className="white-box-container">
            <div className="white-box-title">
                {white_box_title}
            </div>
            <div className="white-box-description">
                {white_box_description}
            </div>

        </div>
    );
};

export default WhiteBoxContainer;