import React from "react";
import CompetitiveLandscape from "./CompetitiveLandscape";
import ExitStratergy from "./ExitStratergy";
import FinancialMetrics from "./FinancialMetrics";
import GovernanceAndSupport from "./GovernanceAndSupport";
import MarketOpportunity from "./MarketOpportunity";
import ProductAndDevelopmentStage from "./ProductAndDevelopmentStage";
import Recommendations from "./Recommendations";
import RevenueModelAndGrowth from "./RevenueModelAndGrowth";
import RiskAnalysis from "./RiskAnalysis";
import StrengthAndWeakness from "./StrengthAndWeakness";
import TeamAndExperience from "./TeamAndExperience";
import TractionAndMarketPenetration from "./TractionAndMarketPenetration";


const AssessmentAnalysisContent = ({ parentStateForActiveTab, analysisTabRefs, analysisTabData }) => {

    return (
        <div className="analysisContent">

            {(analysisTabData && analysisTabData.report && analysisTabData.report["Market Opportunity"]) && (
                <div ref={analysisTabRefs.marketOpportunity}>
                    <MarketOpportunity marketOpportunitySectionData={analysisTabData.report["Market Opportunity"]} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report["Competitive Landscape"]) && (
                <div ref={analysisTabRefs.competitiveLandscape}>
                    <CompetitiveLandscape competitiveLandscapeSectionData={analysisTabData.report["Competitive Landscape"]} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report["Product & Development Stage"]) && (
                <div ref={analysisTabRefs.productAndDevelopmentStage}>
                    <ProductAndDevelopmentStage productAndDevelopmentStageSectionData={analysisTabData.report["Product & Development Stage"]} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report["Financial Metrics"]) && (
                <div ref={analysisTabRefs.financialMetrics}>
                    <FinancialMetrics financialMetricsSectionData={analysisTabData.report["Financial Metrics"]} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report["Traction & Market Penetration"]) && (
                <div ref={analysisTabRefs.tractionAndMarketPenetration}>
                    <TractionAndMarketPenetration tractionAndMarketPenetrationSectionData={analysisTabData.report["Traction & Market Penetration"]} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report["Risk Analysis"]) && (
                <div ref={analysisTabRefs.riskAnalysis}>
                    <RiskAnalysis riskAnalysisSectionData={analysisTabData.report["Risk Analysis"]} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report["Team & Experience"]) && (
                <div ref={analysisTabRefs.teamAndExperience}>
                    <TeamAndExperience teamAndExperienceSectionData={analysisTabData.report["Team & Experience"]} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report["Governance & Support"]) && (
                <div ref={analysisTabRefs.governanceAndSupport}>
                    <GovernanceAndSupport governanceAndSupportSectionData={analysisTabData.report["Governance & Support"]} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report["Revenue Model & Growth"]) && (
                <div ref={analysisTabRefs.revenueModelAndGrowth}>
                    <RevenueModelAndGrowth revenueModelAndGrowthSectionData={analysisTabData.report["Revenue Model & Growth"]} />
                </div>
            )}

            {(analysisTabData && analysisTabData.report && analysisTabData.report["Exit Strategy"]) && (
                <div ref={analysisTabRefs.exitStratergy}>
                    <ExitStratergy exitStratergySectionData={analysisTabData.report["Exit Strategy"]} />
                </div>
            )}

            {/* <div ref={analysisTabRefs.strengthAndWeakness}>
                <StrengthAndWeakness strengthAndWeaknessSectionData={analysisTabData} />
            </div>
            <div ref={analysisTabRefs.recommendations}>
                <Recommendations recommendationsSectionData={analysisTabData} />
            </div> */}

        </div>
    );
};

export default AssessmentAnalysisContent;