import React from "react";

const SectionTitle = ({ sectionTitle }) => {
    return (
        <div className="sectionHeader">
            <div className="section-title">
                {sectionTitle}
            </div>
            <div className="sectionTitleBottomLine">
                
            </div>
        </div>
    );
};

export default SectionTitle;