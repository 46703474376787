import React from 'react';
import { Button } from 'antd';
import "../../css/continueButtonForAssessmentForm.css";

const ContinueButtonForAssessmentForm = ({ isButtonEnabled = true, onClick = {} }) => {
    // console.log('Button render, onClick:', onClick);
    return (
        <Button className="continueButtonForAssessmentForm" disabled={!isButtonEnabled} onClick={onClick}>Continue</Button>
    );
}

export default ContinueButtonForAssessmentForm;
