import React from "react";
import searchIcon from "../../assets/search.png";

const UserChatSearchComponent = () => {
    return (
        <div className="userSearchSection">
            <div className="userSearchBar">
                <img src={searchIcon} alt="Search" />
                <input type="text" placeholder="Search" />
            </div>
        </div >
    );
};

export default UserChatSearchComponent;