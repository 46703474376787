import React from "react";
import averageMedalIcon from "../../../assets/averageRankingIcon.png";
import strengthIconForBenchmark from "../../../assets/strengthIconForBenchmark.png";
import bulbIconForIndustryStandard from "../../../assets/bulbIconForIndustryStandard.png";
import BenchmarkHowToGetThereContainers from "./BenchmarkHowToGetThereContainers";
import aboveAverageRankingIcon from "../../../assets/aboveAverageRankingIcon.png"
import benchmarkSummaryAspectIcon from "../../../assets/benchmarkSummaryAspectIcon.png";
import benchmarkSummaryRankingIcon from "../../../assets/benchmarkSummaryRankingIcon.png"

import "../../../css/summaryOfRankingsForBenchmarks.css";

const SummaryOfRankingsForBenchmarks = ({ benchmarksTabData }) => {

    const ff = benchmarksTabData["report"]
    const data = [
        { 
            no: 1, 
            aspect: 'Market Opportunity', 
            ranking: benchmarksTabData?.["report"]?.["Market Opportunity"]?.Ranking ?? 'N/A' 
        },
        { 
            no: 2, 
            aspect: 'Competitive Landscape', 
            ranking: benchmarksTabData?.["report"]?.["Competitive Landscape"]?.Ranking ?? 'N/A' 
        },
        { 
            no: 3, 
            aspect: 'Product and Development Stage', 
            ranking: benchmarksTabData?.["report"]?.["Product & Development Stage"]?.Ranking ?? 'N/A' 
        },
        { 
            no: 4, 
            aspect: 'Financial Metrics', 
            ranking: benchmarksTabData?.["report"]?.["Financial Metrics"]?.Ranking ?? 'N/A' 
        },
        { 
            no: 5, 
            aspect: 'Traction and Market Penetration', 
            ranking: benchmarksTabData?.["report"]?.["Traction & Market Penetration"]?.Ranking ?? 'N/A' 
        },
        { 
            no: 6, 
            aspect: 'Risk Analysis (PESTEL)', 
            ranking: benchmarksTabData?.["report"]?.["Risk Analysis"]?.Ranking ?? 'N/A' 
        },
        { 
            no: 7, 
            aspect: 'Team and Experience', 
            ranking: benchmarksTabData?.["report"]?.["Team & Experience"]?.Ranking ?? 'N/A' 
        },
        { 
            no: 8, 
            aspect: 'Governance and Support', 
            ranking: benchmarksTabData?.["report"]?.["Governance & Support"]?.Ranking ?? 'N/A' 
        },
        { 
            no: 9, 
            aspect: 'Revenue Model and Growth', 
            ranking: benchmarksTabData?.["report"]?.["Revenue Model & Growth"]?.Ranking ?? 'N/A' 
        },
        { 
            no: 10, 
            aspect: "Exit Strategy", 
            ranking: benchmarksTabData?.["report"]?.["Exit Strategy"]?.Ranking ?? 'N/A' 
        },
        { 
            no: 11, 
            aspect: "Funding and Capital Requirements", 
            ranking: benchmarksTabData?.["report"]?.["Funding and Capital Requirements"]?.Ranking ?? 'N/A' 
        },
    ];    

    const getBadgeClass = (ranking) => {
        switch (ranking) {
            case 'Below Average':
                return 'badge below-average';
            case 'Average':
                return 'badge average';
            default:
                return '';
        }
    };

    return (
        <div className="benchmarkSectionContainer">
            <div className="sectionTitleAndBenchmarkEvalution">
                <div className="benchmarkSectionHeader">SUMMARY OF RANKINGS</div>
                {/* <div className="benchmarkEvaluationRanking aboveAverageRanking">
                    <div className="medalIcon">
                        {true && <img src={aboveAverageRankingIcon} width="44px" height="44px" alt="Above Average Icon" />}
                    </div>
                    <div className="rakingTitleAndDescription">
                        <div className="rankingTitleName">Ranking</div>
                        <div className="rankingDescription aboveAverageRankingDescription">Above Average</div>
                    </div>
                </div> */}
            </div>
            <div className="table-container">
                <table className="ranking-table">
                    <thead>
                        <tr>
                            <th>No.</th>
                            <th>
                                <img src={benchmarkSummaryAspectIcon} alt="Aspect Icon" className="header-icon" />
                                Aspect
                            </th>
                            <th>
                                <img src={benchmarkSummaryRankingIcon} alt="Ranking Icon" className="header-icon" />
                                Ranking
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row) => (
                            <tr key={row.no}>
                                <td className="rowAspectInTable">{row.no}.</td>
                                <td className="rowAspectInTable">{row.aspect}</td>
                                <td>
                                    <span className={getBadgeClass(row.ranking)}>{row.ranking}</span>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default SummaryOfRankingsForBenchmarks;