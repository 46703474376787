import React, { useState } from "react";
import BlogTileComponent from "./BlogTileComponent";
import blogPosts from "../../assets/sampleStuffForDevelopmentAid/blogPosts.json";
import nextArrowIcon from "../../assets/nextArrowIcon.png";
import backArrowIcon from "../../assets/leftArrowIcon.png";


import "../../css/blogs/BlogsContainer.css";  // Optional for custom styles

const BlogsContainer = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const blogsPerPage = 6; // 2 rows of 3 blogs each

    // Pagination logic
    const indexOfLastBlog = currentPage * blogsPerPage;
    const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
    const currentBlogs = blogPosts.slice(indexOfFirstBlog, indexOfLastBlog);

    const totalPages = Math.ceil(blogPosts.length / blogsPerPage);

    const goToNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const goToPreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    // Divide the blogs into rows of 4
    const renderRows = () => {
        const rows = [];
        for (let i = 0; i < currentBlogs.length; i += 3) {
            const rowBlogs = currentBlogs.slice(i, i + 3);
            rows.push(
                <div className="blogRow" key={i}>
                    {rowBlogs.map((blog, index) => (
                        <BlogTileComponent
                            key={index}
                            date={blog.date}
                            title={blog.title}
                            count={index}
                        />
                    ))}
                </div>
            );
        }
        return rows;
    };

    // Function to generate pagination numbers with ellipsis
    const renderPageNumbers = () => {
        const pages = [];
        const visiblePages = 5; // Show 5 pages at a time

        if (totalPages <= visiblePages) {
            // If total pages are less than or equal to visiblePages, display all
            for (let i = 1; i <= totalPages; i++) {
                pages.push(
                    <span
                        key={i}
                        className={`pageNumber ${currentPage === i ? "active" : ""}`}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i < 10 ? `0${i}` : i}
                    </span>
                );
            }
        } else {
            // Handle ellipsis logic for large number of pages
            let startPage = Math.max(1, currentPage - Math.floor(visiblePages / 2));
            let endPage = Math.min(totalPages, startPage + visiblePages - 1);

            if (startPage > 1) {
                pages.push(
                    <span key={1} className="pageNumber" onClick={() => setCurrentPage(1)}>
                        01
                    </span>
                );
                if (startPage > 2) pages.push(<span key="left-ellipsis">...</span>);
            }

            for (let i = startPage; i <= endPage; i++) {
                pages.push(
                    <span
                        key={i}
                        className={`pageNumber ${currentPage === i ? "active" : ""}`}
                        onClick={() => setCurrentPage(i)}
                    >
                        {i < 10 ? `0${i}` : i}
                    </span>
                );
            }

            if (endPage < totalPages) {
                if (endPage < totalPages - 1) pages.push(<span key="right-ellipsis">...</span>);
                pages.push(
                    <span
                        key={totalPages}
                        className="pageNumber"
                        onClick={() => setCurrentPage(totalPages)}
                    >
                        {totalPages < 10 ? `0${totalPages}` : totalPages}
                    </span>
                );
            }
        }

        return pages;
    };

    return (
        <div className="blogsContainer">
            {renderRows()}
            {/* Pagination Controls */}
            {/* <div className="pagination">
                <button onClick={goToPreviousPage} disabled={currentPage === 1}>
                    Previous
                </button>
                <span>Page {currentPage} of {totalPages}</span>
                <button onClick={goToNextPage} disabled={currentPage === totalPages}>
                    Next
                </button>
            </div> */}
            {/* Pagination UI */}
            <div className="pagination">
                <button
                    className="paginationBtn"
                    onClick={goToPreviousPage}
                    disabled={currentPage === 1}
                >
                    <img src={backArrowIcon} />
                </button>
                {renderPageNumbers()}
                <button
                    className="paginationBtn"
                    onClick={goToNextPage}
                    disabled={currentPage === totalPages}
                >
                    {/* &#8594; */}
                    <img src={nextArrowIcon} />
                </button>
            </div>
        </div>
    );
};

export default BlogsContainer;
