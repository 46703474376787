import React from "react"; 

export const getInitials = (businessUserOrProfessionalName) => {
    // Split the string by spaces
    const words = businessUserOrProfessionalName.split(" ");

    // Initialize an empty string for initials
    let initials = "";

    // Take the first character of the first word (if it exists)
    if (words[0]) {
        initials += words[0][0];
    }

    // Take the first character of the second word (if it exists)
    if (words[1]) {
        initials += words[1][0];
    }

    return initials.toUpperCase(); // Optionally convert to uppercase
};