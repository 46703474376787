import React, { useState, useEffect } from "react";

const AssessmentReportSectionNavigationComponentForInvestmentReadiness = ({ parentStateForActiveTab, analysisTabRefs, investmentReadinessTabData }) => {
    const [activeSection, setActiveSection] = useState("Company Overview");

    const handleClickAssessmentReportAnalysisNavigationItem = (sectionName, ref) => {
        setActiveSection(sectionName);  // Set the active section
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className="listOfAssessmentReportSections">

            <div
                className={(activeSection === "Company Overview") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Company Overview", analysisTabRefs.companyOverview)}
            >
                Company Overview
            </div>

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report["Market Opportunity"]) && (
                <div
                    className={(activeSection === "Market Opportunity") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Market Opportunity", analysisTabRefs.marketOpportunity)}
                >
                    Market Opportunity
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report["Competitive Landscape"]) && (
                <div
                    className={(activeSection === "Competitive Landscape") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Competitive Landscape", analysisTabRefs.competitiveLandscape)}
                >
                    Competitive Landscape
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report["Product & Development Stage"]) && (
                <div
                    className={(activeSection === "Product & Development Stage") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Product & Development Stage", analysisTabRefs.productAndDevelopmentStage)}
                >
                    Product & Development Stage
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report["Financial Metrics"]) && (
                <div
                    className={(activeSection === "Financial Metrics") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Financial Metrics", analysisTabRefs.financialMetrics)}
                >
                    Financial Metrics
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report["Traction & Market Penetration"]) && (
                <div
                    className={(activeSection === "Traction & Market Penetration") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Traction & Market Penetration", analysisTabRefs.tractionAndMarketPenetration)}
                >
                    Traction & Market Penetration
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report["Risk Analysis"]) && (
                <div
                    className={(activeSection === "Risk Analysis") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Risk Analysis", analysisTabRefs.riskAnalysis)}
                >
                    Risk Analysis
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report["Team & Experience"]) && (
                <div
                    className={(activeSection === "Team & Experience") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Team & Experience", analysisTabRefs.teamAndExperience)}
                >
                    Team & Experience
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report["Governance & Support"]) && (
                <div
                    className={(activeSection === "Governance & Support") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Governance & Support", analysisTabRefs.governanceAndSupport)}
                >
                    Governance & Support
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report["Revenue Model & Growth"]) && (
                <div
                    className={(activeSection === "Revenue Model & Growth") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Revenue Model & Growth", analysisTabRefs.revenueModelAndGrowth)}
                >
                    Revenue Model & Growth
                </div>
            )}

            {(investmentReadinessTabData && investmentReadinessTabData.report && investmentReadinessTabData.report["Exit Strategy"]) && (
                <div
                    className={(activeSection === "Exit Strategy") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Exit Strategy", analysisTabRefs.operationEfficiency)}
                >
                    Exit Strategy
                </div>
            )}


            {/* <div
                className={(activeSection === "Recommendations & Improvement") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Recommendations & Improvement", analysisTabRefs.recommendations)}
            >
                Recommendations & Improvement
            </div>

            <div
                className={(activeSection === "Conclusion") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Conclusion", analysisTabRefs.investmentReadinessConclusion)}
            >
                Conclusion
            </div> */}
        </div>
    );
};

export default AssessmentReportSectionNavigationComponentForInvestmentReadiness;