import React from "react";
import AccelerateUserGrowth from "./AccelerateUserGrowth";
import MarketOpportunityForExecutableSolutions from "./MarketOpportunityForExecutableSolutions";
import { marketOpportunityActionableSteps, marketOpportunityProblemSoltion } from "./sampleTestContent";
import { competitiveLandscapeActionableSteps, competitiveLandscapeProblemSoltion } from "./sampleTestContent";
import { productAndDevelopmentStageActionableSteps, productAndDevelopmentStageProblemSoltion } from "./sampleTestContent";
import { financialMetricsActionableSteps, financialMetricsProblemSoltion } from "./sampleTestContent";
import { tractionAndMarketPenetrationActionableSteps, tractionAndMarketPenetrationAProblemSoltion } from "./sampleTestContent";
import { riskAnalysisActionableSteps, riskAnalysisProblemSoltion } from "./sampleTestContent";
import { teamAndExperienceActionableSteps, teamAndExperienceProblemSoltion } from "./sampleTestContent";
import { governanceAndSupportActionableSteps, governanceAndSupportProblemSoltion } from "./sampleTestContent";
import { revenueModelAndGrowthActionableSteps, revenueModelAndGrowthProblemSoltion } from "./sampleTestContent";
import { exitStratergyActionableSteps, exitStratergyProblemSoltion } from "./sampleTestContent";
import { conclusion } from "./sampleTestContent";

import "../../../css/AssessmentExecutableSolutionsContent.css";

const AssessmentExecutableSolutionsContent = ({ parentStateForActiveTab, analysisTabRefs, executableSolutionsTabData = {} }) => {
    return (
        <div className="executableSolutionsContent">

            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Market Opportunity"]) && (
                <div ref={analysisTabRefs.marketOpportunity}>
                    <MarketOpportunityForExecutableSolutions
                        sectionTitle="01 Market Opportunity"
                        actionableSteps={marketOpportunityActionableSteps}
                        problemSolution={marketOpportunityProblemSoltion}
                        sectionData={executableSolutionsTabData?.report?.["Market Opportunity"] ?? {}}
                    />
                </div>
            )}

            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Competitive Landscape"]) && (
                <div ref={analysisTabRefs.competitiveLandscape}>
                    <MarketOpportunityForExecutableSolutions
                        sectionTitle="02 Competitive Landscape"
                        actionableSteps={competitiveLandscapeActionableSteps}
                        problemSolution={competitiveLandscapeProblemSoltion}
                        sectionData={executableSolutionsTabData.report["Competitive Landscape"] ?? {}}
                    />
                </div>
            )}

            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Product & Development Stage"]) && (
                <div ref={analysisTabRefs.productAndDevelopmentStage}>
                    <MarketOpportunityForExecutableSolutions
                        sectionTitle="03 Product and Development Stage"
                        actionableSteps={productAndDevelopmentStageActionableSteps}
                        problemSolution={productAndDevelopmentStageProblemSoltion}
                        sectionData={executableSolutionsTabData.report["Product & Development Stage"] ?? {}}
                    />
                </div>
            )}

            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Financial Metrics"]) && (
                <div ref={analysisTabRefs.financialMetrics}>
                    <MarketOpportunityForExecutableSolutions
                        sectionTitle="04 Financial Metrics"
                        actionableSteps={financialMetricsActionableSteps}
                        problemSolution={financialMetricsProblemSoltion}
                        sectionData={executableSolutionsTabData.report["Financial Metrics"] ?? {}}
                    />
                </div>
            )}

            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Traction & Market Penetration"]) && (
                <div ref={analysisTabRefs.tractionAndMarketPenetration}>
                    <MarketOpportunityForExecutableSolutions
                        sectionTitle="05 Traction and Market Penetration"
                        actionableSteps={tractionAndMarketPenetrationActionableSteps}
                        problemSolution={tractionAndMarketPenetrationAProblemSoltion}
                        sectionData={executableSolutionsTabData.report["Traction & Market Penetration"] ?? {}}
                    />
                </div>
            )}

            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Risk Analysis"]) && (
                <div ref={analysisTabRefs.riskAnalysis}>
                    <MarketOpportunityForExecutableSolutions
                        sectionTitle="06 Risk Analysis"
                        actionableSteps={riskAnalysisActionableSteps}
                        problemSolution={riskAnalysisProblemSoltion}
                        sectionData={executableSolutionsTabData.report["Risk Analysis"] ?? {}}
                    />
                </div>
            )}

            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Team & Experience"]) && (
                <div ref={analysisTabRefs.teamAndExperience}>
                    <MarketOpportunityForExecutableSolutions
                        sectionTitle="07 Team and Experience"
                        actionableSteps={teamAndExperienceActionableSteps}
                        problemSolution={teamAndExperienceProblemSoltion}
                        sectionData={executableSolutionsTabData.report["Team & Experience"] ?? {}}
                    />
                </div>
            )}

            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Governance & Support"]) && (
                <div ref={analysisTabRefs.governanceAndSupport}>
                    <MarketOpportunityForExecutableSolutions
                        sectionTitle="08 Governance and Support"
                        actionableSteps={governanceAndSupportActionableSteps}
                        problemSolution={governanceAndSupportProblemSoltion}
                        sectionData={executableSolutionsTabData.report["Governance & Support"] ?? {}}
                    />
                </div>
            )}

            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Revenue Model & Growth"]) && (
                <div ref={analysisTabRefs.revenueModelAndGrowth}>
                    <MarketOpportunityForExecutableSolutions
                        sectionTitle="09 Revenue Model and Growth"
                        actionableSteps={revenueModelAndGrowthActionableSteps}
                        problemSolution={revenueModelAndGrowthProblemSoltion}
                        sectionData={executableSolutionsTabData.report["Revenue Model & Growth"] ?? {}}
                    />
                </div>
            )}

            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Exit Strategy"]) && (
                <div ref={analysisTabRefs.exitStratergy}>
                    <MarketOpportunityForExecutableSolutions
                        sectionTitle="10 Exit Strategy"
                        actionableSteps={exitStratergyActionableSteps}
                        problemSolution={exitStratergyProblemSoltion}
                        sectionData={executableSolutionsTabData.report["Exit Strategy"] ?? {}}
                    />
                </div>
            )}

            {/* <div ref={analysisTabRefs.conclusion}>
                <MarketOpportunityForExecutableSolutions 
                sectionTitle="11 Conclusion" 
                actionableSteps={conclusion} 
                sectionData={executableSolutionsTabData?.report?.["Market Opportunity"] ?? {}} 
                />
            </div> */}
        </div>
    );
};

export default AssessmentExecutableSolutionsContent;