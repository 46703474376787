import React from "react";
import BenchmarksIndividualSectionComponent from "./BenchmarksIndividualSectionComponent";
import CompetitiveLandscapeForBenchmark from "./CompetitiveLandscapeForBenchmark";
import ProductAndDevelopmentStageForBenchmark from "./ProductAndDevelopmentStageForBenchmark";
import TractionAndMarketPenetrationForBenchmark from "./TractionAndMarketPenetrationForBenchmark";
import RiskAnalysisForBenchmark from "./RiskAnalysisForBenchmark";
import TeamAndExperienceForBenchmark from "./TeamAndExperienceForBenchmark";
import GovernanceAndSupportForBenchmark from "./GovernanceAndSupportForBenchmark";
import RevenueModelAndGrowthForBenchmarks from "./RevenueModelAndGrowthForBenchmarks";
import ExitStratergyForBenchmarks from "./ExitStratergyForBenchmarks";
import SummaryOfRankingsForBenchmarks from "./SummaryOfRankingsForBenchmarks";
import FinancialMetricsForBenchmarks from "./FinancialMetricsForBenchmarks";
import FundingAndCapitalRequirementsForBenchmarks from "./FundingAndCapitalRequirementsForBenchmarks";

import "../../../css/AssessmentBenchmarksContent.css";

const AssessmentBenchmarksContent = ({ parentStateForActiveTab, analysisTabRefs, benchmarksTabData = {} }) => {
    return (
        <div className="benchmarkContent">
            {(benchmarksTabData && benchmarksTabData.report && benchmarksTabData.report["Market Opportunity"]) && (
                <div ref={analysisTabRefs.marketOpportunity}>
                    <BenchmarksIndividualSectionComponent sectionData={benchmarksTabData?.report?.["Market Opportunity"] ?? {}} />
                </div>
            )}


            {(benchmarksTabData?.report?.["Competitive Landscape"]) && (
                <div ref={analysisTabRefs.competitiveLandscape}>
                    <CompetitiveLandscapeForBenchmark sectionData={benchmarksTabData?.report?.["Competitive Landscape"] ?? {}} />
                </div>
            )}

            {(benchmarksTabData?.report?.["Product & Development Stage"]) && (
                <div ref={analysisTabRefs.productAndDevelopmentStage}>
                    <ProductAndDevelopmentStageForBenchmark sectionData={benchmarksTabData?.report?.["Product & Development Stage"] ?? {}} />
                </div>
            )}

            {(benchmarksTabData?.report?.["Financial Metrics"]) && (
                <div ref={analysisTabRefs.financialMetrics}>
                    <FinancialMetricsForBenchmarks sectionData={benchmarksTabData?.report?.["Financial Metrics"] ?? {}} />
                </div>
            )}

            {(benchmarksTabData?.report?.["Traction & Market Penetration"]) && (
                <div ref={analysisTabRefs.tractionAndMarketPenetration}>
                    <TractionAndMarketPenetrationForBenchmark sectionData={benchmarksTabData?.report?.["Traction & Market Penetration"] ?? {}} />
                </div>
            )}

            {(benchmarksTabData?.report?.["Risk Analysis"]) && (
                <div ref={analysisTabRefs.riskAnalysis}>
                    <RiskAnalysisForBenchmark sectionData={benchmarksTabData?.report?.["Risk Analysis"] ?? {}} />
                </div>
            )}

            {(benchmarksTabData?.report?.["Team & Experience"]) && (
                <div ref={analysisTabRefs.teamAndExperience}>
                    <TeamAndExperienceForBenchmark sectionData={benchmarksTabData?.report?.["Team & Experience"] ?? {}} />
                </div>
            )}

            {(benchmarksTabData?.report?.["Governance & Support"]) && (
                <div ref={analysisTabRefs.governanceAndSupport}>
                    <GovernanceAndSupportForBenchmark sectionData={benchmarksTabData?.report?.["Governance & Support"] ?? {}} />
                </div>
            )}

            {(benchmarksTabData?.report?.["Revenue Model & Growth"]) && (
                <div ref={analysisTabRefs.revenueModelAndGrowth}>
                    <RevenueModelAndGrowthForBenchmarks sectionData={benchmarksTabData?.report?.["Revenue Model & Growth"] ?? {}} />
                </div>
            )}

            {(benchmarksTabData?.report?.["Exit Strategy"]) && (
                <div ref={analysisTabRefs.exitStratergy}>
                    <ExitStratergyForBenchmarks sectionData={benchmarksTabData?.report?.["Exit Strategy"] ?? {}} />
                </div>
            )}

            {(benchmarksTabData?.report?.["Funding and Capital Requirements"]) && (
                <div ref={analysisTabRefs.fundingAndCapitalRequirements}>
                    <FundingAndCapitalRequirementsForBenchmarks sectionData={benchmarksTabData?.report?.["Funding and Capital Requirements"] ?? {}} />
                </div>
            )}

            {(benchmarksTabData && benchmarksTabData.report) && (
                <div ref={analysisTabRefs.summaryOfRanking}>
                    <SummaryOfRankingsForBenchmarks benchmarksTabData={benchmarksTabData} />
                </div>
            )}

        </div>

    );
};

export default AssessmentBenchmarksContent;