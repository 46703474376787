import React, { useState, useEffect } from "react";
import SubscriptionPricingPlanDetailsContainer from "./SubscriptionPricingPlanDetailsContainer";
import { Flex, Spin, notification } from 'antd';
import apiDetails from "../../assets/apiDetails/apiDetails.json";
import { useNavigate } from 'react-router-dom';
import "../../css/SubscriptionPricingPlan.css";

const SubscriptionPricingContainer = ({ user_details }) => {
    const [businessBasicSubscriptionPackage, setBusinessBasicSubscriptionPackage] = useState({});
    const [businessPremiumSubscriptionPackage, setBusinessPremiumSubscriptionPackage] = useState({});
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false); // New state to track if an error notification has already been shown
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            await Promise.all([
                fetchBusinessPackages()
            ]);
            setLoading(false);
        }
        fetchData();
    }, []);

    const fetchBusinessPackages = async () => {
        try {
            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const get_subscription_packages = "subscription-packages";
            const fetch_url = `${url_domain}/${version_path}/${get_subscription_packages}`;

            const response = await fetch(fetch_url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.user_access_token}`
                }
            });

            // console.log("response from the fetchBusinessPackages API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                openErrorNotification(error_data, "Failed to load available subscriptions");
                return; // Exit the function early
            }

            const data = await response.json();
            // console.log("response data from the fetchBusinessPackages API call response.json() parsing function");
            // console.log(data);

            if (data && data.data) {
                const businessBasicPackageDetails = data.data.filter(subscription => subscription.name === "Business Standard");
                const businessPremiumPackageDetails = data.data.filter(subscription => subscription.name === "Business Premium");
                if (businessBasicPackageDetails.length === 1) {
                    setBusinessBasicSubscriptionPackage(businessBasicPackageDetails[0]);
                }

                if (businessPremiumPackageDetails.length === 1) {
                    setBusinessPremiumSubscriptionPackage(businessPremiumPackageDetails[0]);
                }
            }

        } catch (error) {
            if (!hasError) {
                openErrorNotification({ message: "An unexpected error occurred" }, "Failed to load available subscriptions");
            }
        }
    };

    const openErrorNotification = (notificationParameters, errorTitle = "Error") => {
        // console.loglog("notificationParameters = ", notificationParameters);
        api["error"]({
            message: errorTitle,
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    const handleSubscribeButtonClickCallback = (selected_subscription_package_id) => {
        setLoading(true);
        async function completePaymentProcess() {
            await Promise.all([
                makePayment(selected_subscription_package_id)
            ]);
            // setLoading(false);
        }
        completePaymentProcess();
    };

    const makePayment = async (selected_subscription_package_id) => {
        try {
            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const checkout = "checkout";
            const fetch_url = `${url_domain}/${version_path}/${checkout}/${selected_subscription_package_id}`;

            const response = await fetch(fetch_url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.user_access_token}`
                }
            });

            // console.log("response from the fetchBusinessPackages API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                openErrorNotification(error_data, "Failed to go to the payment page");
                setLoading(false);
                return; // Exit the function early
            }

            const data = await response.json();
            // console.log("response data from the fetchBusinessPackages API call response.json() parsing function");
            // console.log(data);

            if (data && data.payment_url) {
                window.location.href = data.payment_url; 
            }

        } catch (error) {
            if (!hasError) {
                openErrorNotification({ message: "An unexpected error occurred" }, "Failed to go to the payment page");
                setLoading(false);
            }
        }
    };

    return (
        <div className="subscriptionPlansContainer">
            {contextHolder}
            {/* <div className="pricingPlansHeader">Pricing Plans</div>
            <div className="subscriptionPlansForVentures">Subscription Plans for Ventures</div> */}
            <div className="choosePlanText">Choose a Plan</div>
            {(loading) ? (
                <Flex align="center" gap="middle">
                    <Spin size="large" className='spinner-container' />
                </Flex>
            ) : (
                <div className="pricingCategories">
                    <div className="affordableSubscriptionPricingPlan">
                        <SubscriptionPricingPlanDetailsContainer
                            planType="basic"
                            businessSubscriptionPackageDetails={businessBasicSubscriptionPackage}
                            handleSubscribeButtonClickCallback={handleSubscribeButtonClickCallback}
                        />
                    </div>
                    <div className="premiumSubscriptionPricingPlan">
                        <SubscriptionPricingPlanDetailsContainer
                            planType="premium"
                            businessSubscriptionPackageDetails={businessPremiumSubscriptionPackage}
                            handleSubscribeButtonClickCallback={handleSubscribeButtonClickCallback}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default SubscriptionPricingContainer;