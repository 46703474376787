import React from "react";

const GreenBoxContainer = ({ green_box_title = "", green_box_description = "", comingFrom }) => {

    const fontSize24px = ["Market Opportunity"];
    const fontSize16px = ["Competitive Landscape"];

    // Corrected the conditional assignment
    const fontSizeToBeSet = fontSize24px.includes(comingFrom)
        ? "24px"
        : fontSize16px.includes(comingFrom)
            ? "16px"
            : "16px"; // Default fallback to 16px if neither condition is met

    return (
        <div className="green-box-container">
            <div className="green-box-title">
                {green_box_title.toUpperCase()}
            </div>

            {/* Corrected the style prop syntax */}
            <div className="green-box-description" style={{ fontSize: "16px", marginBottom: "0px"}}>
                {green_box_description}
            </div>

        </div>
    );
};

export default GreenBoxContainer;