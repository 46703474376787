import React from "react";

const BlueBoxContainer = ({ blue_box_title = "", blue_box_description = "" }) => {
    return (
        <div className="blue-box-container" >
            <div className="blue-box-title">
                {blue_box_title.toUpperCase()}
            </div>
            <div className="blue-box-description" style={{ fontSize: "16px"}}>
                {blue_box_description}
            </div>

        </div>
    );
};

export default BlueBoxContainer;