import React from "react";
import SectionHeaderForExecutableSolutions from "./SectionHeaderForExecutableSolutions";
import StratergySectionContent from "./StratergySectionContent";

const AccelerateUserGrowth = () => {

    const pointsForMarketingOutreachCampaign = [
        {
            "Content Marketing": "Develop high-quality content (blogs, whitepapers, webinars) targeting potential customers to establish thought leadership and attract organic traffic."
        },

        {
            "Paid Advertising": "Invest in targeted ads on platforms like LinkedIn, Google Ads, and industry-specific websites to reach decision-makers in enterprises."
        },
        {
            "Social Media Engagement": "Increase presence on platforms like LinkedIn and Twitter to engage with potential customers and share success stories and testimonials."
        }
    ];

    const pointsForProductAndAlliances = [
        {
            "Strategic Alliances": " Form partnerships with complementary tech firms, industry influencers, and enterprise solution providers to expand reach and leverage their customer base."
        },

        {
            "Referral Programs": " Implement a referral program offering incentives to existing users and partners for bringing in new customers."
        }
    ];

    const pointsForSalesOptimization = [
        {
            "Sales Team": "Expansion: Hire additional sales personnel with experience in enterprise sales to increase outreach and follow-up with leads."
        },

        {
            "Sales Training": "Invest in training the sales team to effectively communicate the product's value proposition and handle objections."
        },
        {
            "CRM Implementation": "Use a robust CRM system to manage leads, track customer interactions, and optimize the sales funnel."
        }
    ];

    return (
        <div className="accelerateUserGrowthSection">
            <SectionHeaderForExecutableSolutions sectionTitle="Accelerate User Growth" />
            <div className="stratergiesTitleText">Strategies</div>
            <StratergySectionContent stratergySectionTitle="1. Marketing & Outreach Campaign" stratergySectionContentData={pointsForMarketingOutreachCampaign} />
            <StratergySectionContent stratergySectionTitle="2. Product and Alliances" stratergySectionContentData={pointsForProductAndAlliances} />
            <StratergySectionContent stratergySectionTitle="3. Sales Optimization" stratergySectionContentData={pointsForSalesOptimization} />
        </div>
    );
};

export default AccelerateUserGrowth;