import React from "react";
import starIcon from "../../assets/star.png";
import shootingStarIcon from "../../assets/shootingStarIcon.png";

const SubscriptionPricingPlanDetailsContainer = ({ planType = "basic", businessSubscriptionPackageDetails = {}, handleSubscribeButtonClickCallback = () => { } }) => {
    let subscription_type = businessSubscriptionPackageDetails?.name || "";
    let subscription_type_description = "";
    let subscription_header_benefit = "";
    let subscription_benefits = businessSubscriptionPackageDetails?.details?.split(", ") || [];
    let subscription_type_icon = "";
    let subscription_id = businessSubscriptionPackageDetails?.id || "";
    let subscription_cost = businessSubscriptionPackageDetails?.price || "";
    let subscription_period = businessSubscriptionPackageDetails?.subscription_period || "";
    let subscription_currency = businessSubscriptionPackageDetails?.currency || "";

    if (planType === "basic") {
        subscription_type_description = "From diagnosing your current state to providing actionable solutions, we'll help you identify opportunities.";
        subscription_header_benefit = "Affordable";
        subscription_type_icon = { starIcon };
    } else if (planType === "premium") {
        subscription_type_description = "Upgrade your business with our comprehensive remediation package. Enjoy all the benfits of our diagnostic.";
        subscription_header_benefit = "Cancel Anytime";
        subscription_type_icon = { shootingStarIcon };
    }

    const handleSubscribeButtonClick = (e) => {
        // console.log("handleSubscribeButtonClick = ", e);
        const selected_subscription_package_id = e.target.attributes.package_type_id.nodeValue;
        handleSubscribeButtonClickCallback(selected_subscription_package_id);
    }

    return (
        <>
            <div className="subscriptionPricingPlanNameWithIcon">
                <div className="subscriptionPricingIcon"></div>
                <div className="subscriptionPricingBenefit">{subscription_header_benefit}</div>
            </div>

            <div className="subscriptionPricingPlanCardContainer">
                {planType === 'basic' ? (
                    <img src={starIcon} alt="basic" className="subscriptionPricingPlanIcon" />
                ) : (
                    <img src={shootingStarIcon} alt="Premium" className="subscriptionPricingPlanIcon" />
                )}
                <div className="subscriptionPricingPlanName">{subscription_type}</div>
                <div className="subscriptionPricingPlanDescription">{subscription_type_description}</div>
                <div className="subscriptionPricingPlanPriceValue additionalMarginTopForYearlyCost">{subscription_currency} {subscription_cost}</div>
                <div className="subscriptionPricingPlanPriceValuePerMonthOrYearText">{subscription_period}</div>
                {/* <div className="subscriptionPricingPlanPriceValue additionalMarginTopForYearlyCost">{subscription_yeraly_cost}</div>
                <div className="subscriptionPricingPlanPriceValuePerMonthOrYearText">Yearly</div> */}
                <div className="subscriptionPricingPlanBenefitsList">
                    {subscription_benefits.map((benefit, index) => (
                        <div className="subscriptionPricingPlanBenefitItem additionalMarginTopForBenefitItem" key={index}>
                            {/* <img src={approvedBlueBox} alt="check" className="subscriptionPricingPlanBenefitItemBlueBox" /> */}
                            <div class="subscriptionPricingPlanBenefitItemBlueBox">
                                <i class="subscriptionPricingPlanBenefitItemBlueBoxCheckmark">✓</i>
                            </div>
                            <div className="subscriptionPricingPlanBenefitItemName">{benefit}</div>
                        </div>
                    ))}
                </div>
                <div className="subscribeToAPlanButton" package_type={planType} package_type_id={subscription_id} onClick={handleSubscribeButtonClick}>Subscribe</div>
            </div>
        </>
    );
};

export default SubscriptionPricingPlanDetailsContainer;