import React from "react";
import "../../css/blogs/BlogScreenHeader.css";

const BlogScreenHeader = () => {
    return (
        <div className="blogScreenHeader">
            <div className="blogScreenHeaderTitle">Elevate Your Consulting Practice with Anvilist</div>
            <div className="blogScreenHeaderSubtitle">Expand Your Client Base,</div>
            <div className="blogScreenHeaderSubtitle">Streamline</div>
            <div className="blogScreenHeaderSubtitle">Projects, and Grow Your Business</div>
        </div>
    );
};

export default BlogScreenHeader;