import React, { useEffect, useState } from 'react';
import SidebarComponent from './SidebarComponent';
import UserToUserChatSectionComponent from './UserToUserChatSectionComponent';
import attachIcon from "../../assets/fileAttachIcon.png";
import sendButtonIcon from "../../assets/sendButton.png";
import '../../css/UserChatInterface.css';

const UserChatInterface = ({ user_details }) => {

  const [selectedProfessional, setSelectedProfessional] = useState(null);

  const handleActiveChatChange = (professional_id) => {
    // setActiveChatId(chatId);
    // console.log("Active chat changed to:", chatId);  // For demonstration, you can handle it as needed
    // alert(`professional id = ${chatId}`);

    // now here I need to handle the API call to fetch all the messages with respect to the profesional selected by the user.
    // the change the state to load it up. 
    // also need to implement the loader and the error notification component as well with the API call. 
    setSelectedProfessional(professional_id);
  };

  return (
    <div className="chat-container">
      {/* Sidebar */}
      <SidebarComponent onActiveChatChange={handleActiveChatChange} user_details={user_details} />
      {/* Chat Section */}
      {selectedProfessional === null ? (
        <div className='selectProfessionalToChat'>Select a Professional to start a conversation</div>
      ) : (
        <UserToUserChatSectionComponent selectedProfessional={selectedProfessional} user_details={user_details}/>
      )}


    </div>
  );
};

export default UserChatInterface;
