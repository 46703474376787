import React from "react";
import MainNavbarForDashboard from "../components/navbarComponents/NavbarForMainDashbaord";
import SubscriptionPricingContainer from "../components/subscriptionPricing/SubscriptionPricingContainer";
import "../css/FixedScrollingMainScreenContent.css";

const isNavbarForDashboard = true;
const SubscriptionPlans = () => {
    const user_details = JSON.parse(sessionStorage.getItem("user_details"));
    // console.log("in main dashboard page where the logged_in_user_id = ", user_details);
    return (
        <div className="completePageContainer">
            <MainNavbarForDashboard
                isNavbarForDashboard={isNavbarForDashboard}
                user_details={user_details}
                current_tab="pricing"
            />
            <div className="mainContentBelowTheNavbar">
                <SubscriptionPricingContainer user_details={user_details} />
            </div>
        </div>
    )
};

export default SubscriptionPlans;