import React, { useState } from "react";

const AssessmentReportSectionNavigationComponentForExecutableSolutions = ({ parentStateForActiveTab, analysisTabRefs, executableSolutionsTabData = {} }) => {
    const [activeSection, setActiveSection] = useState("Market Opportunity");

    const handleClickAssessmentReportAnalysisNavigationItem = (sectionName, ref) => {
        setActiveSection(sectionName);  // Set the active section
        if (ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
    };

    return (
        <div className="listOfAssessmentReportSections">
            {(executableSolutionsTabData && executableSolutionsTabData.report && executableSolutionsTabData.report["Market Opportunity"]) && (
                <div
                    className={(activeSection === "Market Opportunity") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Market Opportunity", analysisTabRefs.marketOpportunity)}
                >
                    Market Opportunity
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Competitive Landscape"]) && (
                <div
                    className={(activeSection === "Competitive Landscape") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Competitive Landscape", analysisTabRefs.competitiveLandscape)}
                >
                    Competitive Landscape
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Product & Development Stage"]) && (
                <div
                    className={(activeSection === "Product & Development Stage") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Product & Development Stage", analysisTabRefs.productAndDevelopmentStage)}
                >
                    Product & Development Stage
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Financial Metrics"]) && (
                <div
                    className={(activeSection === "Financial Metrics") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Financial Metrics", analysisTabRefs.financialMetrics)}
                >
                    Financial Metrics
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Traction & Market Penetration"]) && (
                <div
                    className={(activeSection === "Traction & Market Penetration") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Traction & Market Penetration", analysisTabRefs.tractionAndMarketPenetration)}
                >
                    Traction & Market Penetration
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Risk Analysis"]) && (
                <div
                    className={(activeSection === "Risk Analysis") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Risk Analysis", analysisTabRefs.riskAnalysis)}
                >
                    Risk Analysis
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Team & Experience"]) && (
                <div
                    className={(activeSection === "Team & Experience") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Team & Experience", analysisTabRefs.teamAndExperience)}
                >
                    Team & Experience
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Governance & Support"]) && (
                <div
                    className={(activeSection === "Governance & Support") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Governance & Support", analysisTabRefs.governanceAndSupport)}
                >
                    Governance & Support
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Revenue Model & Growth"]) && (
                <div
                    className={(activeSection === "Revenue Model & Growth") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Revenue Model & Growth", analysisTabRefs.revenueModelAndGrowth)}
                >
                    Revenue Model & Growth
                </div>
            )}

            {(executableSolutionsTabData?.report?.["Exit Strategy"]) && (
                <div
                    className={(activeSection === "Exit Strategy") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                    onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Exit Strategy", analysisTabRefs.exitStratergy)}
                >
                    Exit Strategy
                </div>
            )}

            {/* <div
                className={(activeSection === "Conclusion") ? 'activeNavigationItem' : 'inActiveNavigationItem'}
                onClick={() => handleClickAssessmentReportAnalysisNavigationItem("Conclusion", analysisTabRefs.conclusion)}
            >
                Conclusion
            </div> */}


        </div>
    );
};

export default AssessmentReportSectionNavigationComponentForExecutableSolutions;