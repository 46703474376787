import React from "react";
import globeIcon from "../../../assets/earth-americas-solid.svg";
import locationIcon from "../../../assets/location.png"
import ideaIcocn from "../../../assets/idea.png";
import currentOperationsIcon from "../../../assets/engineering.png";
import businessCompetitorsIcon from "../../../assets/briefcase.png";

const CompanyOverviewForInvestmentReadiness = ({ completeReportData }) => {
    const unique_factors_list = completeReportData.assessment.what_makes_product_unique;
    // Join the elements of the array with " and "
    const uniqueDescription = unique_factors_list.join(" and ");
    return (
        <div className="companyOverviewSectionContainer">
            <div className="investmentReadinessSectionTitle">Company Overview</div>
            <div className="investmentReadinessCompanyName">{completeReportData.name}</div>
            <div className="investmentReadinessSectionBottomLine"></div>
            <div className="locationDetails">
                <img src={globeIcon} alt="Globe" width="20px" height="20px"></img>
                <div className="locationText">Location: {completeReportData.assessment.company_country}</div>
            </div>
            {/* <div className="addressDetails">
                <img src={locationIcon} alt="Location Pin" width="20px" height="20px"></img>
                <div className="addressDetailsText">Address</div>
            </div> */}
            <div className="industryBussinessActivityMissionContent">
                <div className="industryContent">
                    <div className="industryKey">Industry: </div>
                    <div className="induistryValue">{completeReportData.assessment.company_industry}</div>
                </div>
                {/* <div className="businessActivityContent">
                    <div className="businessActivityKey">Business Acitivity:</div>
                    <div className="businessyActivityValue">Financial Technology & Infrastructure </div>
                </div> */}
                {/* <div className="missionContent">
                    <div className="missionKeyAndValue">
                        Mission: Unicorn Inc produces the best quality single horn for horses. Our mission is to transform the world and make it a better place.
                    </div>
                </div> */}
            </div>
            <div className="twoByTwoSectionDisplay">
                <div className="rowOneContent">
                    <div className="blockOne">
                        <div className="titleAndIcon">
                            <img src={ideaIcocn} alt="Idea" width="20px" height="20px"></img>
                            <div className="blockTitle">Market Focus</div>
                        </div>
                        <div className="blockContent">
                            {/* <div className="blockContentText">Market Focus: Enterprise SaaS Product</div> */}
                            <div className="blockContentText">Current Stage: {completeReportData.assessment.current_stage_of_product}</div>
                        </div>
                    </div>
                    <div className="blockTwo"></div>
                    <div className="blockThree">
                        <div className="titleAndIcon">
                            <img src={ideaIcocn} alt="Idea" width="20px" height="20px"></img>
                            <div className="blockTitle">Revenue</div>
                        </div>
                        <div className="blockContent">
                            <div className="blockContentText">Current Revenue: {completeReportData.assessment.average_monthly_revenue}</div>
                            <div className="blockContentText">Funding Sought: {completeReportData.assessment.how_much_usd_looking_to_raise}</div>
                        </div>
                    </div>
                </div>
                <div className="rowTwoContent">
                    <div className="blockOne">
                        <div className="titleAndIcon">
                            <img src={currentOperationsIcon} alt="Idea" width="20px" height="20px"></img>
                            <div className="blockTitle">Current Operations</div>
                        </div>
                        <div className="blockContent">
                            <div className="blockContentText">Current Stage: {completeReportData.assessment.current_stage_of_product}</div>
                            <div className="blockContentText">Employees: {completeReportData.assessment.paid_employees_available}</div>
                            <div className="blockContentText">Operational Duration: {completeReportData.assessment.how_long_company_operational}</div>
                        </div>
                    </div>
                    <div className="blockTwo"></div>
                    <div className="blockThree">
                        <div className="titleAndIcon">
                            <img src={businessCompetitorsIcon} alt="Idea" width="20px" height="20px"></img>
                            <div className="blockTitle">Competitors</div>
                        </div>
                        <div className="blockContent">
                            <div className="blockContentText">Competitors: {completeReportData.assessment.direct_competitors_identified}</div>
                            <div className="blockContentText">Key Differentiators: {uniqueDescription}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="investmentReadinessSectionDivider"></div>
        </div>
    );
};

export default CompanyOverviewForInvestmentReadiness;