import React from 'react';
import AssessmentFormHeaderTitle from "../components/AssessmentFormHeaderTitle";
import AssessmentFormProgressBar from "../components/AssessmentFormProgressBar";
import AssessmentFormHeaderSectionInfo from "../components/AssessmentFormHeaderSectionInfo";
import AssessmentFormHeaderSaveAndExitButton from "../components/AssessmentFormHeaderSaveAndExitButton";
import '../css/assessmentFormHeaderContainer.css'; // Import the custom CSS file

const AssessmentFormFooter = ({ onPreviousButtonClick = () => { }, onContinueButtonClick = () => { } }) => {
    return (
        <div className="assessmentFormHeaderContainer assessmentFooter">
            <AssessmentFormHeaderSaveAndExitButton
                buttonTitle={"Previous"}
                buttonPosition={"left"}
                onPreviousButtonClick={onPreviousButtonClick}
            />
            <AssessmentFormHeaderTitle
                assessmentFormHeaderTitle=""
            />
            <AssessmentFormHeaderSaveAndExitButton buttonTitle={"Continue"} onContinueButtonClick={onContinueButtonClick} />
        </div>
    );
};

export default AssessmentFormFooter;
