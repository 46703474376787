import React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "./App";
import Dashboard from "./pages/Dashboard";
import AssessmentForm from "./pages/AssessmentForm";
import SignUpPageComponent from "./pages/SignUpPage";
import LoginPageComponent from "./pages/LoginPageComponent";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import ResetPasswordPage from "./pages/ResetPasswordPage";
import MainDashboardPage from "./pages/MainDashbaordPage";
import MessagingPortal from "./components/inbox/MessagingPortal";
import RemediationPage from "./components/RemediationPage/RemediationPage";
import RecommendedProfessinalProfilePage from "./pages/RecommendedProfessinalProfilePage";
import ViewAssessmentReport from "./pages/ViewAssessmentReport";
import BlogScreen from "./components/blogs/BlogScreen";
import SubscriptionPlans from "./pages/SubscriptionPlans";
import MeetingsPage from "./pages/MeetingsPage";
import PaymentProcessingPage from "./pages/PaymentProcessingPage";

export const router = createBrowserRouter([
    {
        path: "/",
        element: <App />
    },
    {
        path: "/dashboard",
        element: <Dashboard />
    },
    {
        path: "/assessmentForm",
        element: <AssessmentForm />
    },
    {
        path: "/signUp",
        element: <SignUpPageComponent />
    },
    {
        path: "/login",
        element: <LoginPageComponent />
    },
    {
        path: "/forgotPassword",
        element: <ForgotPasswordPage />
    },
    {
        path: "/resetPassword",
        element: <ResetPasswordPage />
    },
    {
        path: "/mainDashboard",
        element: <MainDashboardPage />
    },
    {
        path: "/inbox",
        element: <MessagingPortal />
    },
    {
        path: "/remediationTimelines",
        element: <RemediationPage />
    },
    {
        path: "/professionalProfile",
        element: <RecommendedProfessinalProfilePage />
    },
    {
        path: "/viewAssessmentReport",
        element: <ViewAssessmentReport />
    },
    {
        path: "/blogs",
        element: <BlogScreen />
    },
    {
        path: "/pricing",
        element: <SubscriptionPlans />
    },
    {
        path: "/meetings",
        element: <MeetingsPage />
    },
    {
        path: "/payment-success",
        element: <PaymentProcessingPage />
    },
    {
        path: "/api/v1/payment-success",
        element: <PaymentProcessingPage />
    }
]);
