import React, { useEffect, useReducer, useRef, useState } from "react";
import MainNavbarForDashboard from "../components/navbarComponents/NavbarForMainDashbaord";
import AssessmentReportDetailContainer from "../components/viewAssessmentReport/AssessmentReportDetailContainer";
import AssessmentReportActionContainer from "../components/viewAssessmentReport/AssessmentReportActionContainer";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "../css/viewAssessmentReport.css"

const isNavbarForDashboard = true;

const ViewAssessmentReport = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { assessmentReportData } = location.state || {};
    const { completeReportData } = location.state || {};
    // console.log("in the ViewAssessmentReport page where the assessmentReportData = ", assessmentReportData);
    // console.log("in the ViewAssessmentReport page where the completeReportData = ", completeReportData);

    // Extract reports based on the stage
    const analysisTabData = assessmentReportData.find(report => report.stage === "1");
    const executableSolutionsTabData = assessmentReportData.find(report => report.stage === "2");
    const benchmarksTabData = assessmentReportData.find(report => report.stage === "3");
    const investmentReadinessTabData = assessmentReportData.find(report => report.stage === "4");

    // Output the extracted reports (you can use them as needed)
    // console.dir(analysisTabData);
    // console.dir(executableSolutionsTabData);
    // console.dir(benchmarksTabData);
    // console.dir(investmentReadinessTabData);


    // Disable scroll when this component is mounted
    useEffect(() => {
        document.body.style.overflow = 'hidden'; // Disable scrolling
        document.body.style.height = '100vh';    // Ensure height is full screen

        // Cleanup: Enable scroll again when the component unmounts
        return () => {
            document.body.style.overflow = 'auto'; // Restore scrolling
            document.body.style.height = 'auto';   // Reset height
        };
    }, []);

    const user_details = JSON.parse(sessionStorage.getItem("user_details"));
    // console.log("in ViewAssessmentReport where the logged_in_user_id = ", user_details);

    // Create refs for the Analysis section
    const marketOpportunityRef = useRef(null);
    const competitiveLandscapeRef = useRef(null);
    const productAndDevelopmentStageRef = useRef(null);
    const financialMetricsRef = useRef(null);
    const tractionAndMarketPenetrationRef = useRef(null);
    const riskAnalysisRef = useRef(null);
    const teamAndExperienceRef = useRef(null);
    const governanceAndSupportRef = useRef(null);
    const revenueModelAndGrowthRef = useRef(null);
    const exitStratergyRef = useRef(null);
    const recommendationsRef = useRef(null);
    const strengthAndWeaknessRef = useRef(null);

    // refs for the investment readniessTab
    const companyOverviewRef = useRef(null);
    const operationEfficiencyRef = useRef(null);
    const investmentReadinessConclusionRef = useRef(null);
    const investmentOpportunityRef = useRef(null);

    // refs for the executable solutions
    const conclusionSectionRef = useRef(null);

    // refs for benchmark
    const summaryOfRankingRef = useRef(null);
    const fundingAndCapitalRequirementsRef = useRef(null);

    const analysisTabRefs = {
        marketOpportunity: marketOpportunityRef,
        competitiveLandscape: competitiveLandscapeRef,
        productAndDevelopmentStage: productAndDevelopmentStageRef,
        financialMetrics: financialMetricsRef,
        tractionAndMarketPenetration: tractionAndMarketPenetrationRef,
        riskAnalysis: riskAnalysisRef,
        teamAndExperience: teamAndExperienceRef,
        governanceAndSupport: governanceAndSupportRef,
        revenueModelAndGrowth: revenueModelAndGrowthRef,
        exitStratergy: exitStratergyRef,
        recommendations: recommendationsRef,
        strengthAndWeakness: strengthAndWeaknessRef,
        companyOverview: companyOverviewRef,
        operationEfficiency: operationEfficiencyRef,
        investmentReadinessConclusion: investmentReadinessConclusionRef,
        investmentOpportunity: investmentOpportunityRef,
        conclusion: conclusionSectionRef,
        summaryOfRanking: summaryOfRankingRef,
        fundingAndCapitalRequirements: fundingAndCapitalRequirementsRef
    };

    // Define the state in the parent component
    const [parentStateForActiveTab, setParentStateForActiveTab] = useState('Analysis');

    // Function to update the state from child
    const updateStateFromChild = (childData) => {
        setParentStateForActiveTab(childData);
    };

    return (
        <div className="completePageContainerForTheAssessmentReportView">
            <MainNavbarForDashboard
                isNavbarForDashboard={isNavbarForDashboard}
                user_details={user_details}
                current_tab=""
            />
            <div className="mainContentBelowTheNavbarForTheAssessmentReportView">
                <div class="assessmentReportDetailContainer">
                    <AssessmentReportDetailContainer
                        parentStateForActiveTab={parentStateForActiveTab}
                        sendDataToParent={updateStateFromChild}
                        analysisTabRefs={analysisTabRefs}
                        analysisTabData={analysisTabData}
                        executableSolutionsTabData={executableSolutionsTabData}
                        benchmarksTabData={benchmarksTabData}
                        investmentReadinessTabData={investmentReadinessTabData}
                        completeReportData={completeReportData}
                    />
                </div>
                <div class="assessmentReportActionContainer">
                    <AssessmentReportActionContainer
                        parentStateForActiveTab={parentStateForActiveTab}
                        analysisTabRefs={analysisTabRefs}
                        analysisTabData={analysisTabData}
                        executableSolutionsTabData={executableSolutionsTabData}
                        benchmarksTabData={benchmarksTabData}
                        investmentReadinessTabData={investmentReadinessTabData}
                        completeReportData={completeReportData}
                    />
                </div>
            </div>
        </div>
    )
};

export default ViewAssessmentReport;
