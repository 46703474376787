import React, { useEffect, useRef, useState } from 'react';
import attachIcon from "../../assets/fileAttachIcon.png";
import sendButtonIcon from "../../assets/sendButton.png";
import IncomingChatMessageComponent from './IncomingChatMessageComponent';
import OutgoingChatMessageComponent from './OutgoingChatMessageComponent';
import { Flex, Spin, notification } from 'antd';
import userRecentChatListTestingData from "../../assets/sampleStuffForDevelopmentAid/recentChatList.json";

const UserToUserChatSectionComponent = ({ selectedProfessional, user_details }) => {
    const [loading, setLoading] = useState(true);
    const [chatsBetweenUserAndProfessional, setChatsBetweenUserAndProfessional] = useState([]);
    const [userInputMessage, setUserInputMessage] = useState('');
    const [allowMessageSend, setAllowMessageSend] = useState(true);
    const [api, contextHolder] = notification.useNotification();
    const scrollRef = useRef(null);

    const scrollToBottom = () => {
        const scroll = scrollRef.current;
        if (scroll) {
            // Use the last child element for a smooth scroll into view 
            const lastElement = scroll.lastElementChild;
            if (lastElement) {
                lastElement.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }
    useEffect(() => {
        scrollToBottom();
    }, []); // You can include dependencies in the array if it should run when certain props or state change

    useEffect(() => {
        if (selectedProfessional) {
            async function fetchData() {
                await Promise.all([
                    fetchAllUserToProfessionalChats()
                ]);
                setLoading(false);
            }
            fetchData();
        }
    }, [selectedProfessional]);

    const fetchAllUserToProfessionalChats = async () => {
        try {
            const response = await fetch(`https://api.anvillist.com/api/v1/get-messages/${selectedProfessional}`, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.user_access_token}`
                }
            });

            // console.log("response from the get all conversations between user and professional API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                // console.log("error_data", error_data);
                openErrorNotification(error_data);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("response from the get all conversations between user and professional API call response.json() parsing function");
            // console.log(data);

            setChatsBetweenUserAndProfessional(data);
            // filterProjectsByStatus(data)
        } catch (error) {
            // setUserRecentChats(userRecentChatListTestingData);
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    };

    const openErrorNotification = (notificationParameters) => {
        // // console.log("notificationParameters = ", notificationParameters);
        api["error"]({
            message: 'Failed to load conversations with professional',
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    // Function to handle key down event
    const handleKeyDown = (event) => {
        // console.log("hitting the keydown function ", event);
        if (event.key === 'Enter') {
            // Your logic when the Enter key is pressed
            // console.log('Enter key pressed');
            handleMessageSendButtonClick(userInputMessage);
        }
    };

    const handleMessageSendButtonClick = (user_input_message_to_be_sent) => {
        if (allowMessageSend) {
            async function fetchData() {
                await Promise.all([
                    sendUserInputMessage(user_input_message_to_be_sent)
                ]);
            }
            fetchData();
        }
    };

    const sendUserInputMessage = async (user_input_message_to_be_sent) => {
        try {
            const apiJSONBody = {
                "message": user_input_message_to_be_sent,
                "is_file": false
            };

            const response = await fetch(`https://api.anvillist.com/api/v1/send-message/user/${selectedProfessional}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.user_access_token}`
                },
                body: JSON.stringify(apiJSONBody)
            });

            // console.log("response from the send message between user and professional API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                // console.log("error_data", error_data);
                openErrorNotification(error_data);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("response from the send message between user and professional API call response.json() parsing function");
            // console.log(data);

            setChatsBetweenUserAndProfessional([...chatsBetweenUserAndProfessional, data.data]);
            setUserInputMessage("");
        } catch (error) {
            // setUserRecentChats(userRecentChatListTestingData);
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    }

    return (
        <div className="chat-section">
            {contextHolder}
            {loading === true ? (
                <Flex align="center" gap="middle" className="loadingSpinnerForUserChats">
                    <Spin size="large" className="spinner-container" />
                </Flex>
            ) :
                chatsBetweenUserAndProfessional.length === 0 ? (
                    <div className='failedToLoadConversationswithProfessional'>Failed to load conversations with professional</div>
                ) :
                    (
                        <>
                            <div ref={scrollRef} className="chat-messages">
                                {chatsBetweenUserAndProfessional.map((chat, index) => (
                                    <>
                                        {/* Incoming message if chat.sender.id is not same as the id of the logged in user id */}
                                        {chat.sender.id !== user_details.logged_in_user_id && <IncomingChatMessageComponent incomingChatMessage={chat} />}

                                        {/* Outgoing message if chat.sender.id is same as the id of the logged in user id */}
                                        {chat.sender.id === user_details.logged_in_user_id && <OutgoingChatMessageComponent outgoingChatMessage={chat} />}
                                    </>
                                ))}
                            </div>

                            {/* Message input */}
                            <div className="message-input">
                                <label htmlFor="file">
                                    <img src={attachIcon} alt="" />
                                </label>
                                <input
                                    type="file"
                                    id="file"
                                    style={{ display: "none" }}
                                // onChange={handleImg}
                                />
                                <input
                                    type="text"
                                    placeholder="Your message"
                                    className="input-box"
                                    value={userInputMessage}
                                    onChange={(e) => setUserInputMessage(e.target.value)}
                                    onKeyDown={handleKeyDown}
                                />
                                <img src={sendButtonIcon} alt="Send" onClick={() => handleMessageSendButtonClick(userInputMessage)} />
                            </div>
                        </>
                    )}
        </div>
    );

};

export default UserToUserChatSectionComponent;