import React from 'react';
import '../css/assessmentFormHeaderTitle.css'; // Import the custom CSS file

const AssessmentFormHeaderTitle = ({assessmentFormHeaderTitle}) => {
  return (
      <div className="assessmentFormHeaderTitle"></div>
  );
};

export default AssessmentFormHeaderTitle;
