import React, { useState } from 'react';
import { Card, Typography, Input, Button, notification } from 'antd';
import { useNavigate } from "react-router-dom";
import '../../css/RecommendedProfessaionalProfileSectionThree.css'; // Assuming CSS is in this file

const { Meta } = Card;
const { Title, Paragraph } = Typography;

const RecommendedProfessaionalProfileSectionThree = ({ professionalPersonalInformation, user_details }) => {
    const [userInputMessageToProfessional, setUserInputMessageToProfessional] = useState("")
    const [buttonLoading, setButtonLoading] = useState(false);
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    const handleMessageSendButtonClick = (user_input_message_to_be_sent) => {
        if (!buttonDisabled && !buttonLoading) {
            async function fetchData() {
                await Promise.all([
                    sendUserInputMessage(user_input_message_to_be_sent)
                ]);
            }
            fetchData();
        }
    };

    const sendUserInputMessage = async (user_input_message_to_be_sent) => {
        try {
            const apiJSONBody = {
                "message": user_input_message_to_be_sent,
                "is_file": false
            };

            const response = await fetch(`https://api.anvillist.com/api/v1/send-message/user/${professionalPersonalInformation.id}`, {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.user_access_token}`
                },
                body: JSON.stringify(apiJSONBody)
            });

            // console.log("response from the send message between user and professional API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                const error_data = await response.json();
                // console.log("error_data", error_data);
                openErrorNotification(error_data);
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            // console.log("response from the send message between user and professional API call response.json() parsing function");
            // console.log(data);


            setUserInputMessageToProfessional("");
            navigate("/inbox");
        } catch (error) {
            // setUserRecentChats(userRecentChatListTestingData);
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    }

    const openErrorNotification = (notificationParameters) => {
        // // console.log("notificationParameters = ", notificationParameters);
        api["error"]({
            message: 'Failed to load conversations with professional',
            description: notificationParameters.message,
            showProgress: true,
            pauseOnHover: true,
            duration: 0,
        });
    };

    return (
        <Card className="recommened-professional-profile-card" style={{ marginTop: "20px" }}>
            {contextHolder}
            <div className="send-message-to-recommended-professional">
                <Title style={{ "fontSize": "24px", "fontWeight": "700", "marginBottom": "20px" }}>Drop a message.</Title>
                <Input.TextArea
                    className='input-text-area-to-send-message-to-recommended-professional'
                    rows={4}
                    placeholder="Enter your message here"
                    value={userInputMessageToProfessional}
                    onChange={(e) => setUserInputMessageToProfessional(e.target.value)}
                />
                <Button
                    type="primary"
                    className="send-message-to-recommended-professional-btn"
                    loading={buttonLoading}
                    disabled={buttonDisabled}
                    onClick={() => handleMessageSendButtonClick(userInputMessageToProfessional)}
                >
                    Send Message
                </Button>
            </div>
        </Card>
    );
};

export default RecommendedProfessaionalProfileSectionThree;
