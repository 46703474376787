import React from 'react';
import { Button } from 'antd';
import '../css/assessmentFormHeaderSaveAndExitButton.css'; // Import the custom CSS file

const AssessmentFormHeaderSaveAndExitButton = ({ buttonTitle = "", buttonPosition = "default", onPreviousButtonClick = () => { }, onSaveAndExitButtonClick = () => { }, onContinueButtonClick = () => { } }) => {

  if (buttonPosition === "left") {
    return (
      <Button className="save-exit-btn previousButton" style={{ left: "20px" }} onClick={onPreviousButtonClick}>{buttonTitle}</Button>
    );
  }
  else if (buttonTitle === "Save and Exit") {
    return (
      <Button className="save-exit-btn" onClick={onSaveAndExitButtonClick}>{buttonTitle}</Button>
    );
  }

  else if (buttonTitle === "Continue") {
    return (
      <Button className="save-exit-btn previousButton" onClick={onContinueButtonClick}>{buttonTitle}</Button>
    );
  }

  return (
    <Button className="save-exit-btn">{buttonTitle}</Button>
  );
};

export default AssessmentFormHeaderSaveAndExitButton;
