import React from "react";
import sampleCarImage from "../../assets/sampleCarImage.jpg";
import plusIcon from "../../assets/plusIcon.png";
import nextArrowIcon from "../../assets/nextArrowIcon.png";
import "../../css/blogs/BlogTileComponent.css";

const BlogTileComponent = ({ count = 0, date = "", title = "" }) => {
    let blogTileMarginRightClassName = "defaultBlogTileMarginRight";
    if (parseInt(count + 1) % 3 === 0) {
        blogTileMarginRightClassName = "lastBlogTileMarginRight";
    }
    return (
        <div className={`blogTile ${blogTileMarginRightClassName}`}>
            <div className="blogImageWrapper">
                <img src={sampleCarImage} alt="Sample Car Image" className="blogImage" />
            </div>
            <div className="blogDate">{date}</div>
            <div className="blogTitle">{title}</div>
            <div className="blogReadMore">
                <div className="blogReadMoreText">READ MORE</div>
                <img src={plusIcon} alt="+" className="readMorePlusIcon plusIcon" />
                <img src={nextArrowIcon} alt=">" className="readMorePlusIcon arrowIcon" />
            </div>
        </div >
    );
};

export default BlogTileComponent;