import React from "react";
import analysisIcon from "../../../assets/linechartAnalysis.png";
import rankingIcon from "../../../assets/medal.png";
import cactoltvratio from "../../../assets/cactoltvratio.PNG";
import breakeventimeline from "../../../assets/breakeventimeline.PNG";
import monthlyrevenue from "../../../assets/monthlyrevenue.PNG";
import salescycle from "../../../assets/salescycle.PNG";
import projectedAnnualGrowth from "../../../assets/projectedAnnualGrowth.PNG";


const FInancialMetricsForInvestmentReadiness = ({ sectionData = {}, analysis = [], rankingData = "Ranking Value not available", completeReportData = {} }) => {

    var ranking_class_name = "#4B4B4B";
    if (rankingData === "Below Average") {
        ranking_class_name = "#F64C4C";
    } else if (rankingData === "Average") {
        ranking_class_name = "#FE9B0E";
    } else if (rankingData === "Above Average") {
        ranking_class_name = "#47B881";
    } else {
        ranking_class_name = "#4B4B4B";
    }

    let analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    if (Array.isArray(analysis)) {
        analysisSectionTextDescription = analysis[2] ? analysis[2] : "Analysis is not available at this moment for this metric.";
    }
    else if (typeof analysis === "object" && analysis !== null && !Array.isArray(analysis)) {
        analysisSectionTextDescription = analysis["Analysis"];
    } else {
        analysisSectionTextDescription = "Analysis is not available at this moment for this metric.";
    }

    return (
        <div className="investmentReadinessMarketOpportunitySection">
            <div className="investmentReadinessMarketOpportunitySectionHeader">
                <div className="investmentReadinessMarketOpportunitySectionTitleIndex">04</div>
                <div className="investmentReadinessMarketOpportunitySectionTitleName">FINANCIAL METRICS</div>
            </div>
            <div className="investmentReadinessMarketOpportunitySectionContent">
                <div className="nonGraphicalContentForMarketOpportunity">
                    <div className="analysisContentForMarketOpportunity">
                        <div className="analysisContentTitle">
                            <img src={analysisIcon} alt="Idea" width="15px" height="15px"></img>
                            <div className="analsyisTitleName">Analysis</div>
                        </div>
                        <div className="analysisContentDescription">
                            {analysisSectionTextDescription}
                        </div>
                    </div>
                    <div className="rankingContentForMarketOpportunity">
                        <div className="rankingContentTitle">
                            <img src={rankingIcon} alt="Idea" width="20px" height="20px"></img>
                            <div className="rankingTitleName">Ranking</div>
                        </div>
                        <div className="rankingContentDescription">
                            <div className="keyInformationSectionLine">
                                <div className="keyDifferentiatorsKey">CAC to LTV Ratio: </div>
                                <div className="keyDifferentiatorsValue" style={{ color: ranking_class_name }}>{rankingData}</div>
                            </div>

                            <div className="keyInformationSectionLine" style={{ marginTop: "5px" }}>
                                <div className="keyDifferentiatorsKey">Break-even Timeline:</div>
                                <div className="keyDifferentiatorsValue" style={{ color: ranking_class_name }}>{rankingData}</div>
                            </div>

                            <div className="keyInformationSectionLine" style={{ marginTop: "5px" }}>
                                <div className="keyDifferentiatorsKey">Monthly Revenue:</div>
                                <div className="keyDifferentiatorsValue" style={{ color: ranking_class_name }}>{rankingData}</div>
                            </div>

                            <div className="keyInformationSectionLine" style={{ marginTop: "5px" }}>
                                <div className="keyDifferentiatorsKey">Sales Cycle:</div>
                                <div className="keyDifferentiatorsValue" style={{ color: ranking_class_name }}>{rankingData}</div>
                            </div>
                            {sectionData["Projected Annual Growth"] && (
                                <div className="keyInformationSectionLine" style={{ marginTop: "5px" }}>
                                    <div className="keyDifferentiatorsKey">Projected Annual Growth:</div>
                                    <div className="keyDifferentiatorsValue" style={{ color: ranking_class_name }}>{rankingData}</div>
                                </div>
                            )}

                        </div>
                    </div>
                </div>
            </div>
            <div className="financialMetricsDetailsSection">
                <div className="financialMetricsDetailsSectionOne">
                    <div className="financialMetricOne">
                        <img src={cactoltvratio} alt="Idea" width="50px" height="50px"></img>
                        <div className="financialMetricName">CAC to LTV Ratio</div>
                        <div className="financialMetricValue">{(sectionData["CAC & LTV"]) ? sectionData["CAC & LTV"] : "Metric Value not available"}</div>
                    </div>
                    <div className="gapBetweenFinancialMetrics">

                    </div>
                    <div className="financialMetricTwo">
                        <img src={breakeventimeline} alt="Idea" width="50px" height="50px"></img>
                        <div className="financialMetricName">Break-even Timeline</div>
                        <div className="financialMetricValue">{(sectionData["Break-even"]) ? sectionData["Break-even"] : "Metric Value not available"}</div>
                    </div>
                    <div className="gapBetweenFinancialMetrics"></div>
                    <div className="financialMetricThree">
                        <img src={monthlyrevenue} alt="Idea" width="50px" height="50px"></img>
                        <div className="financialMetricName">Monthly Revenue</div>
                        <div className="financialMetricValue">{(sectionData["Monthly Revenue"]) ? sectionData["Monthly Revenue"] : "Metric Value not available"}</div>
                    </div>
                </div>
                <div className="financialMetricsDetailsSectionTwo">
                    <div className="financialMetricOne">
                        <img src={salescycle} alt="Idea" width="50px" height="50px"></img>
                        <div className="financialMetricName">Sales Cycle</div>
                        <div className="financialMetricValue">{(sectionData["Sales Cycle"]) ? sectionData["Sales Cycle"] : "Metric Value not available"}</div>
                    </div>
                    <div className="gapBetweenFinancialMetrics"></div>
                    {sectionData["Projected Annual Growth"] && (
                        <div className="financialMetricTwo">
                            <img src={projectedAnnualGrowth} alt="Idea" width="50px" height="50px"></img>
                            <div className="financialMetricName">Projected Annual Growth</div>
                            <div className="financialMetricValue">{(sectionData["Projected Annual Growth"]) ? sectionData["Projected Annual Growth"] : "Metric Value not available"}</div>
                        </div>
                    )}

                </div>
            </div>
            <div className="rankingContentForMarketOpportunity" style={{ marginTop: "3%" }}>
                <div className="rankingContentTitle">
                    <div className="rankingTitleName">Require Fixes</div>
                </div>
                <div className="analysisContentDescription">
                    {(sectionData && sectionData["Needs Fixing"] && sectionData["Fix"]) && (
                        <>
                            {sectionData["Needs Fixing"]}, {sectionData["Fix"]}
                        </>
                    )}
                </div>
            </div>
            <div className="investmentReadinessSectionDivider"></div>
        </div>
    );
};

export default FInancialMetricsForInvestmentReadiness;