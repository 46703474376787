import React from "react";

const YellowBoxContainer = ({ yellow_box_title = "", yellow_box_description = "" }) => {
    return (
        <div className="yellow-box-container">
            <div className="yellow-box-title">
                {yellow_box_title}
            </div>
            <div className="yellow-box-description" style={{ fontSize: "16px"}}>
                {yellow_box_description}
            </div>

        </div>
    );
};

export default YellowBoxContainer;