import React from "react";

export const marketOpportunityActionableSteps = [
    "Conduct detailed market research to identify underserved segments or verticals within the TAM. Develop targeted marketing campaigns for these niches to accelerate penetration.",
    "Develop targeted marketing campaigns for these niches to accelerate penetration. Evaluate global expansion opportunities to increase the market scope beyond South Africa.",
    "Evaluate global expansion opportunities to increase the market scope beyond South Africa. Conduct detailed market research to identify underserved segments or verticals within the TAM. "
];

export const marketOpportunityProblemSoltion = {
    "problemDescription": "TAM and SAM are promising but not fully capitalized on.",
    "solutionDescription": "Expand market reach and define niche segments."
};

export const competitiveLandscapeActionableSteps = [
    "Initiate customer feedback loops to identify pain points and potential features that competitors don’t offer.",
    "Allocate R&D resources to explore innovative features like AI-driven analytics,  automation, or integration capabilities.",
    "Conduct competitor benchmarking to identify key gaps in their offerings and improve on those."
];

export const competitiveLandscapeProblemSoltion = {
    "problemDescription": "No unique innovations and limited differentiation.",
    "solutionDescription": "Develop and implement a clear product innovation strategy."
};

export const productAndDevelopmentStageActionableSteps = [
    "Set a 6-month timeline to exit beta, establishing clear milestones for testing, feature rollout, and user feedback.",
    "Hire additional developers or product managers to expedite final development.",
    "Launch an aggressive marketing campaign post-launch to promote product updates and new features."
];

export const productAndDevelopmentStageProblemSoltion = {
    "problemDescription": "Stuck in beta stage with no unique innovations introduced.",
    "solutionDescription": "Finalize product development and fast-track product launch."
};

export const financialMetricsActionableSteps = [
    "Analyze CAC by reviewing marketing channels and costs, and allocate resources to the highest ROI channels.",
    "Launch retention strategies such as loyalty programs, customer education, and premium support to increase LTV.",
    "Introduce pricing tiers or add-ons to expand the revenue model.",
    "Adjust sales targets to shorten the break-even timeline by focusing on high-value enterprise customers."
];

export const financialMetricsProblemSoltion = {
    "problemDescription": "CAC slightly lower than LTV, slow revenue growth, and long break-even timeline.",
    "solutionDescription": "Optimize customer acquisition and retention while increasing revenue streams."
};


export const tractionAndMarketPenetrationActionableSteps = [
    "Build partnerships with complementary SaaS products or service providers that cater to similar enterprise clients to create co-branded marketing initiatives.",
    "Develop a referral program incentivizing existing customers to refer new users.",
    "Increase digital marketing efforts with a focus on SEO, content marketing, and paid ads tailored to decision-makers in target industries."
];

export const tractionAndMarketPenetrationAProblemSoltion = {
    "problemDescription": "Low user acquisition (fewer than 100 users), slow market penetration.",
    "solutionDescription": "Increase user acquisition through targeted campaigns and strategic partnerships."
};

export const riskAnalysisActionableSteps = [
    "Begin feasibility studies to explore entering more stable markets outside of South Africa.",
    "Work closely with the legal and compliance team to ensure all regulatory risks are mitigated in current and future markets.",
    "Engage investors early in discussions about risk-sharing mechanisms such as convertible debt or revenue-based financing to protect the company from local economic downturns."
];

export const riskAnalysisProblemSoltion = {
    "problemDescription": "Political and economic instability in South Africa poses a risk.",
    "solutionDescription": "Diversify risk by exploring international expansion and securing investor protections."
};

export const teamAndExperienceActionableSteps = [
    "Recruit senior team members with proven experience in scaling SaaS companies, focusing on product innovation and market expansion.",
    "Consider hiring a Chief Marketing Officer (CMO) to lead aggressive market penetration initiatives.",
    "Develop a mentorship program or hire consultants to provide strategic guidance on scaling and product-market fit."
];

export const teamAndExperienceProblemSoltion = {
    "problemDescription": "The team’s combined 20 years of experience is below industry standards, limiting strategic growth.",
    "solutionDescription": "Enhance the team with additional expertise in business development, marketing, and sales."
};

export const governanceAndSupportActionableSteps = [
    "Add experienced external advisors or board members with SaaS and international business scaling experience.",
    "Implement regular board reviews and establish strong reporting metrics for transparency with investors.",
    "Engage with potential investors early to ensure they can provide not just financial backing but also mentorship, governance, and operational support."
];

export const governanceAndSupportProblemSoltion = {
    "problemDescription": "Limited governance structure and insufficient investor relations.",
    "solutionDescription": "Strengthen governance and secure investor backing for financial and operational guidance."
};

export const revenueModelAndGrowthActionableSteps = [
    "Explore new revenue streams such as value-added services, consulting, or premium product features.",
    "Implement pricing models that reward longer-term commitments (e.g., annual contracts) to increase revenue predictability.",
    "Set more aggressive growth targets and allocate budget toward market expansion or acquisitions to hit higher growth benchmarks (e.g., 30%-50%)."
];

export const revenueModelAndGrowthProblemSoltion = {
    "problemDescription": "Only one stream of revenue and a growth rate of 21%-30%, which is on the low end for SaaS.",
    "solutionDescription": "Expand the revenue model and target faster growth."
};

export const exitStratergyActionableSteps = [
    "Identify potential acquirers early and develop relationships with strategic buyers in the industry",
    "Ensure the company’s financials, customer base, and product are attractive by achieving key performance indicators (KPIs) important to potential acquirers (e.g., strong user growth, profitability, or differentiated tech).",
    "Create acquisition marketing materials, including growth stories, use cases, and testimonials, to appeal to acquirers when the time comes."
];

export const exitStratergyProblemSoltion = {
    "problemDescription": "The exit strategy (acquisition or share buyback) is standard, but it lacks clarity on how to attract potential acquirers.",
    "solutionDescription": "Build a robust acquisition strategy that appeals to likely buyers."
};

export const conclusion = [
    "By addressing these key areas with detailed solutions, the company can close gaps in its investment readiness, making it a stronger candidate for securing investment and scaling in the competitive Enterprise SaaS market. Prioritizing product innovation, expanding the team, optimizing financial performance, and enhancing market penetration will significantly improve the company’s overall prospects."
];