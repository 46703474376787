import React, { useState, useEffect } from 'react';
import { Input } from 'antd';
import ReportCard from '../mainDashboardPageComponents/ReportCard';
import testingTimelineCardsData from "../../assets/sampleStuffForDevelopmentAid/timelineDataToRenderCards.json"
import { useNavigate } from 'react-router-dom';
import { Flex, Spin } from 'antd';
import { formatToLocalDate, formatTimeStringToDateForTimeline, formatTimelineEndDate } from "../FormattedDate";
import NoDataViewComponent from '../mainDashboardPageComponents/NoDataView';
import '../../css/RemediationTabsComponent.css';

const { Search } = Input;

const pendingTimelineReports = [...testingTimelineCardsData, ...testingTimelineCardsData];
const acceptedTimelineReport = [...pendingTimelineReports, ...testingTimelineCardsData];
const closedTimelineReports = [...acceptedTimelineReport, ...testingTimelineCardsData];

const RemediationTabsComponent = ({ user_details }) => {
    const [activeTab, setActiveTab] = useState('All');
    const [allRemediations, setAllRemediations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [allPendingRemediations, setAllPendingRemediations] = useState([]);
    const [allAcceptedRemediations, setAllAcceptedRemediations] = useState([]);
    const [allClosedRemediations, setAllClosedRemediations] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            await Promise.all([
                fetchAllRemediations()
            ]);
            setLoading(false);
        }
        fetchData();
    }, []);

    const fetchAllRemediations = async () => {
        try {
            const response = await fetch("https://api.anvillist.com/api/v1/business/remediation", {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.user_access_token}`
                }
            });

            // console.log("response from the get all remediations API call ");
            // console.log(response);

            const data = await response.json();
            // console.log("response data from the get all remediations API call response.json() parsing function");
            // console.log(data);

            setAllRemediations(data.data);
            filterProjectsByStatus(data)
        } catch (error) {
            // console.error('Error fetching data from API 3 - get all remediations API call', error);
        }
    };

    const filterProjectsByStatus = (remediations) => {
        // console.log("check the remediations value coming into the filtered funtion = ", remediations.data);
        // Initialize empty lists for each status
        const pendingProjects = [];
        const closedProjects = [];
        const acceptedProjects = [];

        // Iterate through the list of projects
        remediations.data.forEach((project) => {
            // Check the status of each project and push it to the appropriate list
            switch (project.status) {
                case 'pending':
                    pendingProjects.push(project);
                    break;
                case 'closed':
                    closedProjects.push(project);
                    break;
                case 'accepted':
                    acceptedProjects.push(project);
                    break;
                default:
                // console.log(`Unknown status: ${project.status}`);
            }
        });

        setAllAcceptedRemediations(acceptedProjects);
        setAllClosedRemediations(closedProjects);
        setAllPendingRemediations(pendingProjects);
        // console.log("filtered out data");
    };

    const renderAllTimelineReports = () => {
        if (allRemediations.length == 0) {
            return <NoDataViewComponent title="No remediations available at this time" />
        }
        return allRemediations.map((report, index) => (
            <ReportCard
                key={index}
                title={report.client}
                subtitle={`Gap fixes for ${report.name}`}
                startDate={formatTimeStringToDateForTimeline(report.createdAt)}
                endDate={formatTimelineEndDate(report.end_date)}
                progressCompleted={report.completion_level}
                profileImage={report.id}
                actionButtonTitle="For Timeline"
                remediationButtonContainer="For Remediation"
                remediationType={report.status}
                remediationReportData={report}
                user_details={user_details}
            />
        ));
    };

    const renderPendingTimelineReports = () => {
        if (allPendingRemediations.length == 0) {
            return <NoDataViewComponent title="No pending remediations available at this time" />
        }
        return allPendingRemediations.map((report, index) => (
            <ReportCard
                key={index}
                title={report.client}
                subtitle={`Gap fixes for ${report.name}`}
                startDate={formatTimeStringToDateForTimeline(report.createdAt)}
                endDate={formatTimelineEndDate(report.end_date)}
                progressCompleted={report.completion_level}
                profileImage={report.id}
                actionButtonTitle="For Timeline"
                remediationButtonContainer="For Remediation"
                remediationType={report.status}
                remediationReportData={report}
                user_details={user_details}
            />
        ));
    };

    const renderAccpetedTimelineReports = () => {
        if (allAcceptedRemediations.length == 0) {
            return <NoDataViewComponent title="No accepted remediations available at this time" />
        }
        return allAcceptedRemediations.map((report, index) => (
            <ReportCard
                key={index}
                title={report.client}
                subtitle={`Gap fixes for ${report.name}`}
                startDate={formatTimeStringToDateForTimeline(report.createdAt)}
                endDate={formatTimelineEndDate(report.end_date)}
                progressCompleted={report.completion_level}
                profileImage={report.id}
                actionButtonTitle="For Timeline"
                remediationButtonContainer="For Remediation"
                remediationType={report.status}
                remediationReportData={report}
                user_details={user_details}
            />
        ));
    };

    const renderClosedTimelineReports = () => {
        if (allClosedRemediations.length == 0) {
            return <NoDataViewComponent title="No closed remediations available at this time" />
        }
        return allClosedRemediations.map((report, index) => (
            <ReportCard
                key={index}
                title={report.client}
                subtitle={`Gap fixes for ${report.name}`}
                startDate={formatTimeStringToDateForTimeline(report.createdAt)}
                endDate={formatTimelineEndDate(report.end_date)}
                progressCompleted={report.completion_level}
                profileImage={report.id}
                actionButtonTitle="For Timeline"
                remediationButtonContainer="For Remediation"
                remediationType={report.status}
                remediationReportData={report}
                user_details={user_details}
            />
        ));
    };

    const renderContent = () => {
        if (loading) {
            // return <div>Loading...</div>;
            return (
                <Flex align="center" gap="middle">
                    <Spin size="large" className='spinner-container' />
                </Flex>
            );
        }
        // console.log("all pending remediations = ", allPendingRemediations);
        // console.log("all accepted remediuations == ", allAcceptedRemediations);
        // console.log("all closed remediations == ", allClosedRemediations);
        switch (activeTab) {
            case 'All':
                return (
                    <div style={{ width: '100%' }}>
                        {/* <ReportCard /> */}
                        {renderAllTimelineReports()}
                    </div>
                );
            case 'Pending':
                // return <div className="content">This is the Recommendations content. </div>;
                return (
                    <div>
                        {renderPendingTimelineReports()}
                    </div>
                )
            case 'Accepted':
                // return <div className="content">This is the Timeline content.</div>;
                return (
                    <div>
                        {renderAccpetedTimelineReports()}
                    </div>
                )
            case 'Closed':
                // return <div className="content">This is the Timeline content.</div>;
                return (
                    <div>
                        {renderClosedTimelineReports()}
                    </div>
                )
            default:
                return null;
        }
    };

    return (
        <div>
            <div className="remediation-tabs-container">
                <div className="remediation-tabs">
                    <div
                        className={`remediation-tab ${activeTab === 'All' ? 'active' : ''}`}
                        onClick={() => setActiveTab('All')}
                    >
                        All
                    </div>
                    <div
                        className={`remediation-tab ${activeTab === 'Pending' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Pending')}
                    >
                        Pending
                    </div>
                    <div
                        className={`remediation-tab ${activeTab === 'Accepted' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Accepted')}
                    >
                        Accepted
                    </div>
                    <div
                        className={`remediation-tab ${activeTab === 'Closed' ? 'active' : ''}`}
                        onClick={() => setActiveTab('Closed')}
                    >
                        Closed
                    </div>
                </div>
            </div>
            {renderContent()}
        </div>
    );
}

export default RemediationTabsComponent;
