import React, { useState, useEffect } from 'react';
import { Menu, Dropdown } from 'antd';
import anvillistLogo from "../../assets/anvillist-logo.png";
import { useNavigate } from "react-router-dom";
import messagingIcon from "../../assets/mailicon.png";
import rawCenterMenuConfig from "../../assets/navbarItems/centerMenuItems.json";
import apiDetails from "../../assets/apiDetails/apiDetails.json";

import "../../css/main_navigation_bar.css";

const MainNavbarForDashboard = ({ isNavbarForDashboard = true, user_details = {}, current_tab = null }) => {
    const [current, setCurrent] = useState(current_tab == null ? '' : current_tab);
    const [centerMenuItemsToRender, setcenterMenuItemsToRender] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            const startMenuItemObject = await startMenuItem();
            const dashboardMenuItemObject = await dashboardMenuItem();
            const remediationMenuItemObject = await remediationMenuItem();
            const meetingsMenuItemObject = await meetingsMenuItem();
            let centerMenuItemsToRenderObjects = [startMenuItemObject, dashboardMenuItemObject, remediationMenuItemObject, meetingsMenuItemObject];

            const pricingObjectReturned = await checkSubscriptionStatus();
            if (pricingObjectReturned != null) {
                centerMenuItemsToRenderObjects.push(pricingObjectReturned);
            }
            setcenterMenuItemsToRender(centerMenuItemsToRenderObjects);
            // setLoading(false);
        }
        fetchData();
    }, []);

    const startMenuItem = async () => {
        const startMenuObject = {
            ...rawCenterMenuConfig.Start,
            className: current === 'start' ? 'menu-item menu-item-selected' : 'menu-item',
        };
        return startMenuObject;
    };

    const dashboardMenuItem = async () => {
        const dashboardMenuObject = {
            ...rawCenterMenuConfig.Dashboard,
            className: current === 'dashboard' ? 'menu-item menu-item-selected' : 'menu-item',
        };
        return dashboardMenuObject;
    };

    const remediationMenuItem = async () => {
        const remediationMenuObject = {
            ...rawCenterMenuConfig.Remediation,
            className: current === 'remediation' ? 'menu-item menu-item-selected' : 'menu-item',
        };
        return remediationMenuObject;
    };

    const dataRoomMenuItem = async () => {
        const dataRoomMenuObject = {
            ...rawCenterMenuConfig["Data Room"],
            className: current === 'data room' ? 'menu-item menu-item-selected' : 'menu-item',
        };
        return dataRoomMenuObject;
    };

    const meetingsMenuItem = async () => {
        const meetingsMenuObject = {
            ...rawCenterMenuConfig.Meetings,
            className: current === 'meetings' ? 'menu-item menu-item-selected' : 'menu-item',
        };
        return meetingsMenuObject;
    };

    const showCaseMenuItem = async () => {
        const showCaseMenuObject = {
            ...rawCenterMenuConfig["Show Case"],
            className: current === 'show case' ? 'menu-item menu-item-selected' : 'menu-item',
        };
        return showCaseMenuObject;
    };

    const checkSubscriptionStatus = async () => {
        try {
            const url_domain = apiDetails.domain;
            const version_path = apiDetails.version_path;
            const subscription_status = "subscription-status";
            const fetch_url = `${url_domain}/${version_path}/${subscription_status}`;

            const response = await fetch(fetch_url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user_details.user_access_token}`
                }
            });

            // console.log("response from the fetchBusinessPackages API call ");
            // console.log(response);

            // Check if the response status is not acceptable
            if (!response.ok) {
                // const error_data = await response.json();
                // openErrorNotification(error_data, "Failed to load available subscriptions");
                return null; // Exit the function early
            }

            const data = await response.json();
            // console.log("response data from the fetchBusinessPackages API call response.json() parsing function");
            // console.log(data);

            if (data && (data["active"] || data.active)) {
                return null;
            } else {
                const pricingObject = {
                    ...rawCenterMenuConfig.Pricing,
                    className: current === 'pricing' ? 'menu-item menu-item-selected' : 'menu-item',
                }
                return pricingObject;
            }

        } catch (error) {
            return null;
        }
    };

    const handleClick = (e) => {
        const currentKey = e.key;
        // console.log(e);
        setCurrent(currentKey);

        if (currentKey == "start") {
            navigate("/dashboard");
        } else if (currentKey == "dashboard") {
            navigate("/mainDashboard");
        } else if (currentKey == "remediation") {
            navigate("/remediationTimelines");
        } else if (currentKey == "blogs") {
            navigate("/blogs");
        } else if (currentKey == "pricing") {
            navigate("/pricing");
        } else if (currentKey === "meetings") {
            navigate("/meetings");
        }
    };

    // This handles clicks from the dropdown menu
    const handleDropdownClick = ({ key }) => {
        // console.log('Dropdown Menu Clicked:', key);
        if (key === 'logout') {
            // Handle logout logic here
            // console.log('Logout clicked');
            const userAccessToken = localStorage.getItem("anvillist_user_access_token");
            logoutUser(userAccessToken);
        }
    };

    const logoutUser = (userAccessToken) => {
        fetch('https://api.anvillist.com/api/v1/logout', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${userAccessToken}`
            },
            body: JSON.stringify({})
        })
            .then(async response => {
                if (!response.ok) {
                    const errorData = await response.json();
                    // console.error('Logout failed:', errorData);
                    throw new Error('Failed to logout');
                }
                return response.json();
            })
            .then(data => {
                // console.log('Logout successful:', data);
                localStorage.removeItem("anvillist_user_access_token");
                sessionStorage.removeItem("user_details");
                navigate("/login");
            })
            .catch(error => {
                // console.error('An error occurred:', error);
            });
    };

    const goToInbox = () => {
        navigate("/inbox");
    };

    const logoItem = {
        label: (
            <div className="logo-item">
                <img
                    src={anvillistLogo}
                    alt="Logo"
                    className="logo-img"
                />
            </div>
        ),
        key: 'logo',
        disabled: true
    };

    // const centerMenuItems = [
    //     {
    //         label: 'Dashboard',
    //         key: 'dashboard',
    //         className: current === 'dashboard' ? 'menu-item menu-item-selected' : 'menu-item',
    //     },
    //     {
    //         label: 'Shared Space',
    //         key: 'shared-space',
    //         className: current === 'shared-space' ? 'menu-item menu-item-selected' : 'menu-item',
    //     },
    //     {
    //         label: 'Templates',
    //         key: 'templates',
    //         className: current === 'templates' ? 'menu-item menu-item-selected' : 'menu-item',
    //     },
    //     {
    //         label: 'Meeting Rooms',
    //         key: 'meeting-rooms',
    //         className: current === 'meeting-rooms' ? 'menu-item menu-item-selected' : 'menu-item',
    //     },
    // ];

    const centerMenuItems = [
        {
            label: 'Start',
            key: 'start',
            className: current === 'start' ? 'menu-item menu-item-selected' : 'menu-item',
        },

        {
            label: 'Dashboard',
            key: 'dashboard',
            className: current === 'dashboard' ? 'menu-item menu-item-selected' : 'menu-item',
        },

        {
            label: 'Remediation',
            key: 'remediation',
            className: current === 'remediation' ? 'menu-item menu-item-selected' : 'menu-item',
        },

        {
            label: 'Data Room',
            key: 'data room',
            className: current === 'data room' ? 'menu-item menu-item-selected' : 'menu-item',
        },

        {
            label: 'Meetings',
            key: 'meetings',
            className: current === 'meetings' ? 'menu-item menu-item-selected' : 'menu-item',
        },
        {
            label: 'Show Case',
            key: 'show case',
            className: current === 'show case' ? 'menu-item menu-item-selected' : 'menu-item',
        },
        {
            label: 'Pricing',
            key: 'pricing',
            className: current === 'pricing' ? 'menu-item menu-item-selected' : 'menu-item',
        }
    ];

    const rightMenuItems = [
        {
            label: (
                <div className="mailIconContainer">
                    <img
                        src={messagingIcon}
                        alt="Logo"
                        className="mailIcon"
                        onClick={goToInbox}
                    />
                </div>
            ),
            key: "mailIcon",
            className: current === 'mailIcon' ? 'menu-item menu-item-selected' : 'menu-item',
        },
        {
            label: <div className="userType">Business</div>,
            key: 'business',
            className: current === 'business' ? 'menu-item menu-item-selected' : 'menu-item',
            disabled: true,
        },
        {
            label: <div className="divider-line" />,
            key: 'divider',
            disabled: true,
            className: 'menu-divider'
        },
        {
            label: (
                <Dropdown overlay={
                    <Menu className="dropdown-menu" onClick={handleDropdownClick}>
                        <Menu.Item key="logout" className="dropdown-menu-item">Logout</Menu.Item>
                    </Menu>
                } placement="bottomLeft">
                    <span>{user_details.user_name}</span>
                </Dropdown>
            ),
            key: 'user',
            className: current === 'user' ? 'menu-item user-profile-menu-item' : 'menu-item',
        },
    ];

    const emptySetOfMenuItems = [];

    return (
        <div className="navbar-menu">
            <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" items={[logoItem]} />
            {isNavbarForDashboard ? (
                <>
                    <div className="center-menu-container">
                        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="center-menu" items={centerMenuItemsToRender} />
                    </div>
                    <div className="right-menu-container">
                        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="right-menu-item" items={rightMenuItems} />
                    </div>
                </>
            ) : (
                <>
                    <div className="center-menu-container">
                        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="center-menu" items={emptySetOfMenuItems} />
                    </div>
                    <div className="right-menu-container">
                        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" className="right-menu-item" items={emptySetOfMenuItems} />
                    </div>
                </>
            )}
        </div>
    );
};

export default MainNavbarForDashboard;
