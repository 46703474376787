import React from "react";
import "../../css/blogs/BlogFooter.css";

// Import your social media images
import instagramIcon from '../../assets/instagramIcon.png'; // Path to your image
import twitterIcon from '../../assets/twitterIcon.png';   // Path to your image
import linkedinIcon from '../../assets/linkedinIcon.png'; // Path to your image
import emailIcon from '../../assets/emailIcon.png';       // Path to your image

const Footer = () => {
    return (
        <footer className="footer-container">
            <div className="footer-content">
                <div className="footer-left">
                    <p>Copyright © 2024 Anvil Business Center, Inc. All rights reserved.</p>
                    <p>We are committed to fostering growth, collaboration and innovation.</p>
                </div>
                <div className="footer-right">
                    <a href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">
                        <img src={instagramIcon} alt="Facebook" className="social-icon" />
                    </a>
                    <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
                        <img src={twitterIcon} alt="Twitter" className="social-icon" />
                    </a>
                    <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
                        <img src={linkedinIcon} alt="LinkedIn" className="social-icon" />
                    </a>
                    <a href="mailto:info@example.com" target="_blank" rel="noopener noreferrer">
                        <img src={emailIcon} alt="Email" className="social-icon" />
                    </a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
